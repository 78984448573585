import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";

const Home = ({ title, heading, description, image }) => {
  return (
    <>
      <Navbar />
      <div className="personal__payment">
        <div className="personal__description">
          <h1>{title}</h1>
          <h1>{heading}</h1>
          <p>{description}</p>
          <Link to="/options">
            <button>Get Started</button>
          </Link>
        </div>
        <div className="personal__imaging">
          <img src={image} alt="Personal avatar" />
        </div>
      </div>
    </>
  );
};

export default Home;
