import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { supportedCountries } from "../../../lib/links";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import {
  onboardUser,
  onboardMerchant,
  createMerchant,
  createRoles,
} from "../../../app/userReducer/actions";
import { useNavigate } from "react-router-dom";

// Merchant Account First Step
const FirstStep = ({ handlePosition, merchantDetails, setMerchantDetails }) => {
  return (
    <div>
      <h3>Merchant Account</h3>
      <p>
        Get started to a seamless merchant experience with Rohipay Merchant
        Account
      </p>
      <div className="form__group">
        <select
          name="country"
          value={merchantDetails.country}
          onChange={(e) =>
            setMerchantDetails({ ...merchantDetails, country: e.target.value })
          }
        >
          <option hidden>Country</option>
          {supportedCountries.map((country, index) => (
            <option value={country.name} key={index + country.name}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form__group">
        <input
          type="text"
          name="business name"
          placeholder="Registered business name"
          className="form__control"
          value={merchantDetails.businessName}
          onChange={(e) =>
            setMerchantDetails({
              ...merchantDetails,
              businessName: e.target.value,
            })
          }
        />
      </div>
      <div className="form__group">
        <select
          name="business type"
          value={merchantDetails.type}
          onChange={(e) =>
            setMerchantDetails({ ...merchantDetails, type: e.target.value })
          }
        >
          <option hidden>Business Type</option>
          <option value="Private Company for Profit">
            Private Company for Profit
          </option>
          <option value="Public Company for Profit">
            Public Company for Profit
          </option>
          <option value="Sole Trader/Self Employed">
            Sole Trader/Self Employed
          </option>
          <option value="Non-Profit Company">Non-Profit Company</option>
        </select>
      </div>
      <div className="form__group">
        <input
          type="text"
          name="busines email"
          placeholder="Business Email"
          className="form__control"
          value={merchantDetails.email}
          onChange={(e) =>
            setMerchantDetails({ ...merchantDetails, email: e.target.value })
          }
        />
      </div>
      <div className="form__group">
        <textarea
          col="30"
          rows="10"
          name="registered number"
          placeholder="Business Description"
          className="form__control"
          value={merchantDetails.description}
          onChange={(e) =>
            setMerchantDetails({
              ...merchantDetails,
              description: e.target.value,
            })
          }
        ></textarea>
      </div>
    </div>
  );
};

// Confirm Merchant Address
const ConfirmAddress = ({
  handlePosition,
  merchantAddress,
  setMerchantAddress,
  merchantDetails,
}) => {
  return (
    <div>
      <h3>Confirm Business Address</h3>
      <p>
        Ensure the registered address is the one you used to officially register
        your business with your government body
      </p>
      <div className="form__group">
        <select
          name="business country"
          defaultValue={merchantDetails.country}
          disabled
        >
          <option hidden>{merchantDetails.country}</option>
        </select>
      </div>
      <div className="form__group">
        <input
          type="text"
          name="business address"
          placeholder="Business Address"
          className="form__control"
          value={merchantAddress.address}
          onChange={(e) =>
            setMerchantAddress({ ...merchantAddress, address: e.target.value })
          }
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="city"
          placeholder="Business City"
          className="form__control"
          value={merchantAddress.city}
          onChange={(e) =>
            setMerchantAddress({ ...merchantAddress, city: e.target.value })
          }
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="post code"
          placeholder="Postal Code"
          className="form__control"
          value={merchantAddress.postalCode}
          onChange={(e) =>
            setMerchantAddress({
              ...merchantAddress,
              postalCode: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

// Set Business Industry
const ConfirmIndustry = ({
  handlePosition,
  additionalDetails,
  setAdditionalDetails,
}) => {
  return (
    <div>
      <h3>Additional Information</h3>
      <div className="form__group">
        <select
          name="industry"
          value={additionalDetails.industry}
          onChange={(e) =>
            setAdditionalDetails({
              ...additionalDetails,
              industry: e.target.value,
            })
          }
        >
          <option hidden>Industry</option>
          <option value="Betting">Betting</option>
          <option value="Binary">Binary</option>
          <option value="Casino">Casino</option>
          <option value="Corporate">Corporate</option>
          <option value="Education">Education</option>
          <option value="Finance">Finance</option>
          <option value="Forex">Forex</option>
          <option value="Gambling">Gambling</option>
          <option value="Gaming">Gaming</option>
          <option value="Integrator">Integrator</option>
          <option value="Lotteries">Lotteries</option>
          <option value="Other">Other</option>
          <option value="Poker">Poker</option>
          <option value="Reseller">Reseller</option>
          <option value="Services">Services</option>
          <option value="Shopping">Shopping</option>
          <option value="Travel">Travel</option>
          <option value="Utilities">Utilities</option>
        </select>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="website"
              placeholder="Business Website"
              className="form__control"
              value={additionalDetails.website}
              onChange={(e) =>
                setAdditionalDetails({
                  ...additionalDetails,
                  website: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="social1"
              placeholder="Twitter Handle"
              className="form__control"
              value={additionalDetails.twitter}
              onChange={(e) =>
                setAdditionalDetails({
                  ...additionalDetails,
                  twitter: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-m">
          <div className="form__group">
            <input
              type="text"
              name="social2"
              placeholder="Linkedin Username"
              className="form__control"
              value={additionalDetails.linkedin}
              onChange={(e) =>
                setAdditionalDetails({
                  ...additionalDetails,
                  linkedin: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="social3"
              placeholder="Instagram Handle"
              className="form__control"
              value={additionalDetails.instagram}
              onChange={(e) =>
                setAdditionalDetails({
                  ...additionalDetails,
                  instagram: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="social4"
              placeholder="Facebook Handle"
              className="form__control"
              value={additionalDetails.facebook}
              onChange={(e) =>
                setAdditionalDetails({
                  ...additionalDetails,
                  facebook: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <select
              name="size of business"
              value={additionalDetails.businessSize}
              onChange={(e) =>
                setAdditionalDetails({
                  ...additionalDetails,
                  businessSize: e.target.value,
                })
              }
            >
              <option hidden>Business Size</option>
              <option value="1 Person">1</option>
              <option value="2-10">2-10</option>
              <option value="10-50">10-50</option>
              <option value="50-150">50-150</option>
              <option value="150-above">Above 150</option>
            </select>
          </div>
        </div>
        <h5 className="mt-3 mb-3">Account Handler Information</h5>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form__group">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={additionalDetails.firstName}
                onChange={(e) =>
                  setAdditionalDetails({
                    ...additionalDetails,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form__group">
              <input
                type="text"
                name="middleName"
                placeholder="Middle Name"
                value={additionalDetails.middleName}
                onChange={(e) =>
                  setAdditionalDetails({
                    ...additionalDetails,
                    middleName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form__group">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={additionalDetails.lastName}
                onChange={(e) =>
                  setAdditionalDetails({
                    ...additionalDetails,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form__group">
              <label htmlFor="Date of Birth">Date of Birth</label>
              <input
                type="date"
                name="lastName"
                // placeholder="Last Name"
                value={additionalDetails.dateOfBirth}
                onChange={(e) =>
                  setAdditionalDetails({
                    ...additionalDetails,
                    dateOfBirth: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Accounts = ({ account, setAccount }) => {
  return (
    <div>
      <h3>Account Preference</h3>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="form__group">
            <select
              name="currency"
              value={account.currency}
              onChange={(e) =>
                setAccount({ ...account, currency: e.target.value })
              }
            >
              <option hidden>Select Currency</option>
              <option value="USD">Dollars</option>
              <option value="EUR">Euro</option>
              <option value="GBP">Pound</option>
            </select>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="password"
              name="pin"
              placeholder="Account Pin"
              value={account.pin}
              onChange={(e) => setAccount({ ...account, pin: e.target.value })}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="password"
              name="confirm pin"
              placeholder="Confirm account pin"
              value={account.confirmPin}
              onChange={(e) =>
                setAccount({ ...account, confirmPin: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MerchantAlt = () => {
  const { userID } = useSelector((state) => state.user);
  console.log(userID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [merchantDetails, setMerchantDetails] = useState({
    country: "",
    businessName: "",
    type: "",
    description: "",
    email: "",
  });
  const [merchantAddress, setMerchantAddress] = useState({
    address: "",
    city: "",
    postalCode: "",
    street: "",
    region: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    industry: "",
    website: "",
    twitter: "",
    facebook: "",
    instagram: "",
    businessSize: "",
    linkedin: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
  });
  const [account, setAccount] = useState({
    currency: "",
    pin: "",
    confirmPin: "",
  });
  const [position, setPosition] = useState(0);
  const [showAccount, setShowAccount] = useState(false);
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const { loading, success, accountSuccess } = useSelector(
    (state) => state.utilities
  );
  const handlePosition = () => {
    if (
      !merchantDetails.country ||
      !merchantDetails.businessName ||
      !merchantDetails.type ||
      !merchantDetails.description ||
      !merchantDetails.email
    ) {
      toast.error("Incomplete credentials");
      return;
    }
    if (position === 0) {
      setPosition(1);
      return;
    }
  };

  const moveToFinal = async () => {
    if (
      !merchantAddress.address ||
      !merchantAddress.city ||
      !merchantAddress.postalCode
    ) {
      toast.error("All fields are required!");
      return;
    }

    dispatch(
      onboardMerchant({
        agId: 0,
        businesstype: merchantDetails.type,
        companyname: merchantDetails.businessName,
        contactInfo: [
          {
            ccId: 0,
            description: "",
            type: "",
            value: "",
          },
        ],
        description: merchantDetails.description,
        email: merchantDetails.email,
        firstname: additionalDetails.firstName,
        industry: additionalDetails.industry,
        lastname: additionalDetails.lastName,
        location: {
          city: merchantAddress.city,
          country: merchantDetails.country,
          geoLocation: {
            glId: 0,
            latitude: location.latitude,
            longitude: location.longitude,
            radius: 0,
          },
          localId: 0,
          state: merchantAddress.region,
          street: merchantAddress.street,
        },
        logo: "",
        merchantId: "",
        middlename: additionalDetails.middleName,
        mobile: "",
        socialMedia: [
          {
            ccId: 0,
            description: "Twitter Account",
            type: "Tiwtter",
            value: additionalDetails.twitter,
          },
          {
            ccId: 1,
            description: "Facebook Account",
            type: "Facebook",
            value: additionalDetails.facebook,
          },
          {
            ccId: 2,
            description: "Instagram Account",
            type: "Instagram",
            value: additionalDetails.instagram,
          },
        ],
        status: "Active",
        website: additionalDetails.website,
      })
    );
  };

  const handleSubmit = async () => {
    if (!additionalDetails.industry) {
      toast.error("Please provide business industry");
      return;
    }
    if (!additionalDetails.businessSize) {
      toast.error("Please provide business size");
      return;
    }
    if (
      !additionalDetails.website &&
      !additionalDetails.twitter &&
      !additionalDetails.facebook &&
      !additionalDetails.instagram &&
      !additionalDetails.linkedin
    ) {
      toast.error("Please enter atleast one social media handle");
      return;
    }
    if (
      !additionalDetails.firstName ||
      !additionalDetails.lastName ||
      !additionalDetails.dateOfBirth
    ) {
      toast.error("Please complete personal details fields");
      return;
    }

    dispatch(
      createMerchant({
        agId: 0,
        businesstype: merchantDetails.type,
        companyname: merchantDetails.businessName,
        contactInfo: [
          {
            ccId: 0,
            description: "",
            type: "",
            value: "",
          },
        ],
        description: merchantDetails.description,
        email: merchantDetails.email,
        firstname: additionalDetails.firstName,
        industry: additionalDetails.industry,
        lastname: additionalDetails.lastName,
        location: {
          city: merchantAddress.city,
          country: merchantDetails.country,
          geoLocation: {
            glId: 0,
            latitude: location.latitude,
            longitude: location.longitude,
            radius: 0,
          },
          localId: 0,
          state: merchantAddress.region,
          street: merchantAddress.street,
        },
        logo: "",
        merchantId: "",
        middlename: additionalDetails.middleName,
        mobile: "",
        socialMedia: [
          {
            ccId: 0,
            description: "Twitter Account",
            type: "Tiwtter",
            value: additionalDetails.twitter,
          },
          {
            ccId: 1,
            description: "Facebook Account",
            type: "Facebook",
            value: additionalDetails.facebook,
          },
          {
            ccId: 2,
            description: "Instagram Account",
            type: "Instagram",
            value: additionalDetails.instagram,
          },
        ],
        status: "Active",
        website: additionalDetails.website,
      })
    );
  };

  const createAccount = async () => {
    if (!account.currency) {
      toast.error("Choose a currency before proceeding");
      return;
    }
    dispatch(
      onboardUser({
        accountName: `${additionalDetails.firstName} ${additionalDetails.lastName}`,
        accountOpeningDate: Date.now(),
        accountType: "Merchant",
        availableBalance: "0",
        clearedBalance: "0",
        currency: account.currency,
        customerId: userID,
        decimal: "0",
        email: merchantDetails.email,
        fullName: `${additionalDetails.firstName} ${additionalDetails.lastName}`,
        holdBalance: "0",
        lastTransactionDate: "2022-01-20T16:25:43.544Z",
        maximumBalance: "10000",
        minimumBalance: "100000",
        phoneNumber: account.phoneNumber,
        pin: account.pin,
        status: "Active",
        unclearedBalance: "0",
      })
    );

    dispatch(
      createRoles({
        profileid: userID,
        profiletype: "Merchant",
        status: "Active",
        userid: sessionStorage.getItem("email"),
        userrole: "OWNER",
      })
    );
  };

  const steps = [
    {
      component: (
        <FirstStep
          handlePosition={handlePosition}
          merchantDetails={merchantDetails}
          setMerchantDetails={setMerchantDetails}
        />
      ),
    },
    {
      component: (
        <ConfirmAddress
          handlePosition={moveToFinal}
          merchantAddress={merchantAddress}
          setMerchantAddress={setMerchantAddress}
          merchantDetails={merchantDetails}
        />
      ),
    },
    {
      component: (
        <ConfirmIndustry
          handlePosition={handleSubmit}
          additionalDetails={additionalDetails}
          setAdditionalDetails={setAdditionalDetails}
        />
      ),
    },
  ];

  // useEffect
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(displayLocationInfo);
    }
    //eslint-disable-next-line
  }, []);
  function displayLocationInfo(position) {
    const lng = position.coords.longitude;
    const lat = position.coords.latitude;

    console.log(`longitude: ${lng} | latitude: ${lat}`);
    setLocation({ ...location, latitude: lat, longitude: lng });
  }

  useEffect(() => {
    if (success && position === 1) {
      setPosition(position + 1);
    }
  }, [success, position]);

  useEffect(() => {
    if (userID && position === 2) {
      setPosition(position + 1);
      setShowAccount(true);
    }
  }, [userID, position]);

  useEffect(() => {
    if (accountSuccess) {
      navigate("/dashboard");
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [accountSuccess]);
  return (
    <>
      <div className="merchant__section p-4 bg-light">
        {!showAccount ? (
          <>
            {steps[position].component}
            <div className="form__group">
              {position === 0 && (
                <button onClick={handlePosition}>Confirm</button>
              )}
              {position === 1 && (
                <button onClick={moveToFinal}>
                  {!loading ? (
                    "Confirm"
                  ) : (
                    <ReactLoading type="spin" width={30} height={30} />
                  )}
                </button>
              )}
              {position === 2 && (
                <button onClick={handleSubmit}>
                  {!loading ? (
                    "Create Merchant Profile"
                  ) : (
                    <ReactLoading type="spin" width={30} height={30} />
                  )}
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <Accounts account={account} setAccount={setAccount} />
            <button onClick={createAccount}>
              {!loading ? (
                "Create Account"
              ) : (
                <ReactLoading type="spin" width={30} height={30} />
              )}
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default MerchantAlt;
