// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import {
  getUserRoles,
  getUserDetails,
  getUserAccounts,
  getProfilePics,
  getRequiredDocuments,
} from "../../app/userReducer/actions";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const token =
  sessionStorage.getItem("token") || sessionStorage.getItem("accountItem");

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const DashboardNav = () => {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const { userPrivileges, profile, accounts, profilePics } = useSelector(
    (state) => state.user
  );
  const [personal, setPersonal] = useState(false);
  // console.log(accounts);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    }
    // eslint-disable-next-line
  }, [sessionStorage.getItem("user")]);

  useEffect(() => {
    if (userPrivileges.length > 0) {
      userPrivileges.forEach((privilege) => {
        if (privilege.profiletype === "Personal") {
          setPersonal(true);
        }
      });
    }
  }, [userPrivileges]);

  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getProfilePics());
    dispatch(getRequiredDocuments());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userPrivileges.length > 0) {
      if (!sessionStorage.getItem("user")) {
        sessionStorage.setItem("user", JSON.stringify(userPrivileges[0]));
      }
    }
  });

  useEffect(() => {
    if (user) {
      dispatch(getUserDetails());
      dispatch(getUserAccounts());
    }
  }, [user, dispatch]);

  const addToSessionStorage = (data) => {
    sessionStorage.setItem("user", JSON.stringify(data));
    dispatch(getUserDetails());
    dispatch(getUserAccounts());
    window.location.reload();
  };

  // console.log(userPrivileges);

  return (
    <nav className="dashboard__nav">
      <div className="first__layer"></div>
      <div className="second__layer">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <div className="profile__pics">
              {profilePics ? (
                <img src={profilePics} alt="Profile Pic" width={30} />
              ) : (
                <span>
                  <i className="fas fa-user"></i>
                </span>
              )}
            </div>
            Welcome,{" "}
            {profile && profile.firstName
              ? profile.firstName
              : profile.firstname}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <p className="dropdown__para">
              Account Number:{" "}
              {accounts.length > 0 ? accounts[0].accountNumber : ""}
            </p>
            <Dropdown.Item>
              <Link to="/dashboard/profile">
                <p className="dropdown__para utils__para">
                  <i className="fas fa-user"></i> Profile
                </p>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/dashboard/settings">
                <p className="dropdown__para utils__para">
                  {" "}
                  <i className="fas fa-cog"></i>Settings
                </p>
              </Link>
            </Dropdown.Item>
            {userPrivileges.length > 0 && (
              <>
                {userPrivileges.map((privilege, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      addToSessionStorage(privilege);
                    }}
                  >
                    <h5>{privilege.profiletype} Account</h5>
                    <p>{privilege.userrole[0].apriviledge}</p>
                  </Dropdown.Item>
                ))}
              </>
            )}

            {!personal ? (
              <Dropdown.Item>
                <Link to="/dashboard/create-personal">
                  <p className="dropdown__para utils__para">
                    <i className="fas fa-plus"></i> Create Personal Account
                  </p>
                </Link>
              </Dropdown.Item>
            ) : (
              ""
            )}

            {/* Creating new accounts */}
            <div className="new__merchant">
              <Dropdown.Item>
                <Link to="/dashboard/create-merchant">
                  <p className="dropdown__para utils__para">
                    {" "}
                    <i className="fas fa-plus"></i> Create Merchant Account
                  </p>
                </Link>
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default DashboardNav;
