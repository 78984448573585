import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
// import { allCountries } from "../../lib/countries";
import ReactLoading from "react-loading";
import { dates, months, supportedCountries } from "../../lib/links";
import {
  signUpUser,
  onboardUser,
  createRoles,
} from "../../app/userReducer/actions";
import { useNavigate } from "react-router-dom";

const PersonalAccounts = ({
  pin,
  setPin,
  number,
  setNumber,
  confirmPin,
  setConfirmPin,
}) => {
  return (
    <div>
      <h3 className="text-center">Finish Account Setup</h3>
      <div className="form__group">
        <input
          type="text"
          className="form__control"
          placeholder="Phone Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="password"
              className="form__control"
              placeholder="Account pin"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="password"
              className="form__control"
              placeholder="Confirm Account pin"
              value={confirmPin}
              onChange={(e) => setConfirmPin(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PersonalSignUpAlt = () => {
  const navigate = useNavigate();
  const [gender, setGender] = useState("Male");
  // const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [showAccountForm, setShowAccountForm] = useState(true);
  // eslint-disable-next-line
  const [showAccount, setShowAccount] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user);
  console.log(state);
  const { loading, accountSuccess } = useSelector((state) => state.utilities);
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [personal, setPersonal] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    language: "",
    currency: "",
    sourcesOfFunds: "",
    street: "",
    building: "",
    region: "",
    town: "",
    country: "",
    postCode: "",
    email: "",
  });
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [number, setNumber] = useState("");

  const nigeriaCurrency = [
    {
      cdef: "",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "",
      currency: "GBP",
      status: "Active",
    },
    {
      cdef: "default",
      currency: "NGN",
      status: "Active",
    },
  ];

  const europeanCurrencies = [
    {
      cdef: "default",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "",
      currency: "GBP",
      status: "Active",
    },
  ];

  const usCurrencies = [
    {
      cdef: "",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "default",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "",
      currency: "GBP",
      status: "Active",
    },
  ];

  const ukCurrencies = [
    {
      cdef: "",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "default",
      currency: "GBP",
      status: "Active",
    },
  ];

  const nigeriaDefault = nigeriaCurrency.filter(
    (currency) => currency.cdef === "default"
  );
  const europeanDefault = europeanCurrencies.filter(
    (currency) => currency.cdef === "default"
  );
  const usDefault = usCurrencies.filter(
    (currency) => currency.cdef === "default"
  );
  const ukDefault = ukCurrencies.filter(
    (currency) => currency.cdef === "default"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !personal.firstName ||
      !personal.lastName ||
      !personal.street ||
      !personal.building ||
      !personal.town ||
      !personal.country ||
      !personal.postCode
    ) {
      toast.warning("Incomplete credentials!");
      return;
    }
    if (pin !== confirmPin) {
      toast.warning("Pin and confirm must be equal");
      return;
    }

    dispatch(
      signUpUser({
        bvn: "",
        customerAddress: [
          {
            city: personal.town,
            country: personal.country,
            postalCode: personal.postCode,
            state: personal.region,
            status: "",
            street: personal.street,
          },
        ],
        dateOfBirth: `${date}${month}${year}`,
        email: personal.email,
        firstName: personal.firstName,
        gender: gender,
        identity: [
          {
            countryOfIssue: "",
            idNumber: "",
            idType: "",
            status: "",
          },
        ],
        lastName: personal.lastName,
        localGovernment: "",
        nationality: personal.country,
        otherNames: "",
        phone: 0,
        salutation: "",
        status: "Active",
        type: "Personal",
        personalcurrencies:
          personal.country === "Nigeria"
            ? nigeriaCurrency
            : personal.country === "United States"
            ? usCurrencies
            : personal.country === "United Kingdom"
            ? ukCurrencies
            : europeanCurrencies,
      })
    );
  };

  const handleAccountCreation = () => {
    if (!number || !pin || !confirmPin) {
      toast.warning("Incomplete details");
      return;
    }
    if (pin !== confirmPin) {
      toast.warning("Pin and confirm pin must be equal");
      return;
    }
    if (pin.length !== 4) {
      toast.error("Pin must be equal to 4");
      return;
    }
    dispatch(
      onboardUser({
        accountName: `${personal.firstName} ${personal.lastName}`,
        accountOpeningDate: Date.now(),
        accountType: "Personal",
        availableBalance: "0",
        clearedBalance: "0",
        currency:
          personal.country === "Nigeria"
            ? nigeriaDefault[0].currency
            : personal.country === "United States"
            ? usDefault[0].currency
            : personal.country === "United Kingdom"
            ? ukDefault[0].currency
            : europeanDefault[0].currency,
        customerId: state.userID,
        decimal: "0",
        email: personal.email,
        fullName: `${personal.firstName} ${personal.lastName}`,
        holdBalance: "0",
        lastTransactionDate: "2022-01-20T16:25:43.544Z",
        maximumBalance: "10000",
        minimumBalance: "100000",
        phoneNumber: "",
        pin: pin,
        status: "Active",
        unclearedBalance: "0",
      })
    );

    dispatch(
      createRoles({
        profileid: state.userID,
        profiletype: "Personal",
        status: "Active",
        userid: sessionStorage.getItem("email"),
        userrole: "OWNER",
      })
    );
  };

  useEffect(() => {
    if (state.userID) {
      setShowAccountForm(false);
      setShowAccount(true);
    }
    // eslint-disable-next-line
  }, [state.userID]);
  useEffect(() => {
    if (accountSuccess) {
      navigate("/dashboard");
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [accountSuccess]);
  return (
    <>
      <div className="personal__signup bg-light">
        {/* <h3>Create a Personal Account</h3> */}
        {showAccountForm && (
          <div>
            <div className="personal__details">
              <h3>Personal Profile Details</h3>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form__group">
                    <input
                      type="text"
                      name="first name"
                      placeholder="First Name"
                      value={personal.firstName}
                      onChange={(e) =>
                        setPersonal({ ...personal, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form__group">
                    <input
                      type="text"
                      name="last name"
                      placeholder="Last Name"
                      value={personal.lastName}
                      onChange={(e) =>
                        setPersonal({ ...personal, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <label htmlFor="date">Date of Birth</label>
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <select
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    >
                      <option hidden>Day</option>
                      {dates.map((date, index) => (
                        <option key={date + index} value={date}>
                          {date}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <select
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option hidden>Month</option>
                      {months.map((month, index) => (
                        <option key={month + index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <select
                      name="year"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option hidden>Birth year</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>
                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1967">1967</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>
                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1957">1957</option>
                      <option value="1956">1956</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                      <option value="1949">1949</option>
                      <option value="1948">1948</option>
                      <option value="1947">1947</option>
                      <option value="1946">1946</option>
                      <option value="1945">1945</option>
                      <option value="1944">1944</option>
                      <option value="1943">1943</option>
                      <option value="1942">1942</option>
                      <option value="1941">1941</option>
                      <option value="1940">1940</option>
                      <option value="1939">1939</option>
                      <option value="1938">1938</option>
                      <option value="1937">1937</option>
                      <option value="1936">1936</option>
                      <option value="1935">1935</option>
                      <option value="1934">1934</option>
                      <option value="1933">1933</option>
                      <option value="1932">1932</option>
                      <option value="1931">1931</option>
                      <option value="1930">1930</option>
                      <option value="1929">1929</option>
                      <option value="1928">1928</option>
                      <option value="1927">1927</option>
                      <option value="1926">1926</option>
                      <option value="1925">1925</option>
                      <option value="1924">1924</option>
                      <option value="1923">1923</option>
                      <option value="1922">1922</option>
                      <option value="1921">1921</option>
                      <option value="1920">1920</option>
                      <option value="1919">1919</option>
                      <option value="1918">1918</option>
                      <option value="1917">1917</option>
                      <option value="1916">1916</option>
                      <option value="1915">1915</option>
                      <option value="1914">1914</option>
                      <option value="1913">1913</option>
                      <option value="1912">1912</option>
                      <option value="1911">1911</option>
                      <option value="1910">1910</option>
                      <option value="1909">1909</option>
                      <option value="1908">1908</option>
                      <option value="1907">1907</option>
                      <option value="1906">1906</option>
                      <option value="1905">1905</option>
                      <option value="1904">1904</option>
                      <option value="1903">1903</option>
                      <option value="1902">1902</option>
                      <option value="1901">1901</option>
                      <option value="1900">1900</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form__group">
                <label htmlFor="gender">Gender</label>
                <select
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="form__group">
                    <input
                      type="email"
                      name="gender"
                      placeholder="Personal Email"
                      value={personal.email}
                      onChange={(e) =>
                        setPersonal({ ...personal, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                {/* <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="currency"
                    placeholder="Currency"
                    value={personal.currency}
                    onChange={(e) =>
                      setPersonal({ ...personal, currency: e.target.value })
                    }
                  />
                </div>
              </div> */}
              </div>
              {/* <div className="form__group">
              <input
                type="text"
                name="sources of funds"
                placeholder="Sources of funds/wealth"
                value={personal.sourcesOfFunds}
                onChange={(e) =>
                  setPersonal({ ...personal, sourcesOfFunds: e.target.value })
                }
              />
            </div> */}
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form__group">
                    <input
                      type="text"
                      name="street"
                      placeholder="Street"
                      value={personal.street}
                      onChange={(e) =>
                        setPersonal({ ...personal, street: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form__group">
                    <input
                      type="text"
                      name="building"
                      placeholder="Building"
                      value={personal.building}
                      onChange={(e) =>
                        setPersonal({ ...personal, building: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form__group">
                    <input
                      type="text"
                      name="region"
                      placeholder="Region (Optional)"
                      value={personal.region}
                      onChange={(e) =>
                        setPersonal({ ...personal, region: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form__group">
                    <input
                      type="text"
                      name="town/city"
                      placeholder="Town/City"
                      value={personal.town}
                      onChange={(e) =>
                        setPersonal({ ...personal, town: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <input
                  type="text"
                  name="Postal Code"
                  placeholder="Post Code"
                  value={personal.postCode}
                  onChange={(e) =>
                    setPersonal({ ...personal, postCode: e.target.value })
                  }
                />
              </div>
              <div className="form__group">
                <select
                  name="country"
                  value={personal.country}
                  onChange={(e) =>
                    setPersonal({
                      ...personal,
                      country: e.target.value,
                    })
                  }
                >
                  <option hidden>Select Country</option>
                  {supportedCountries.map((country, index) => (
                    <option value={country.name} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form__group">
                <button onClick={handleSubmit}>
                  {!loading ? (
                    "Create Personal Account"
                  ) : (
                    <ReactLoading
                      type="spin"
                      color="#fff"
                      width={30}
                      height={30}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {showAccount && (
          <>
            <PersonalAccounts
              pin={pin}
              setPin={setPin}
              confirm={confirmPin}
              setConfirmPin={setConfirmPin}
              number={number}
              setNumber={setNumber}
            />{" "}
            <button onClick={handleAccountCreation}>
              {!loading ? (
                "Complete"
              ) : (
                <ReactLoading type="spin" color="#fff" width={30} height={30} />
              )}
            </button>
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default PersonalSignUpAlt;
