import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import ReactLoading from "react-loading";
import "../styles/Options.css";
import { url } from "../lib/links";
import axios from "axios";
import { Link } from "react-router-dom";

const Options = () => {
  const [categories, setCatories] = useState([]);
  const [loading, setLoading] = useState(true);
  // Fetch Options
  useEffect(() => {
    axios
      .get(`${url}/accounts/getAllAccountType`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log(response.data);
        setCatories(response.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Navbar />
      {/* OPtions container */}
      <div className="container-fluid accounts__category shadow-lg bg-body rounded">
        <h3>Choose Account Category</h3>
        {/* Categories */}
        <div className="options">
          {!loading ? (
            categories.map((category) => (
              <div
                className={`category__card ${category.accountType}`}
                key={category.accountType}
              >
                <Link to={`/account/${category.accountType}`}>
                  {category.accountType === "Personal" && (
                    <i className="fas fa-user-clock"></i>
                  )}
                  {category.accountType === "Business" && (
                    <i className="fas fa-briefcase"></i>
                  )}
                  {category.accountType === "Merchant" && (
                    <i className="fas fa-cart-plus"></i>
                  )}
                  <h5>{category.accountType} Account</h5>
                </Link>
              </div>
            ))
          ) : (
            <ReactLoading type="spin" color="#003cbe" />
          )}
        </div>
        <div className="company__links">
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Security Policy</p>
          <p>About Us</p>
        </div>
      </div>
    </div>
  );
};

export default Options;
