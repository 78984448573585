import React from "react";
import {Outlet} from 'react-router-dom'

const MerchantMain = () => {
  return (
    <div className="main__section">
      <Outlet />
    </div>
  );
};

export default MerchantMain;
