import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import ReactLoading from "react-loading";
import { signUp } from "../../app/userReducer/actions";
import { useNavigate } from "react-router-dom";
import { url } from "../../lib/links";
const Token = ({
  user,
  setUser,
  agree,
  setAgree,
  position,
  setPosition,
  type,
  token,
  setToken,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.token === "") {
      toast.error("Kindly provide OTP");
      return;
    }
    if (parseInt(user.token !== token)) {
      toast.error("Token is incorrect");
      return;
    }
    if (!agree) {
      toast.error("Accept terms and conditions");
      return;
    }
    setLoading(true);
    const body = {
      email: user.email,
      password: user.password,
      username: user.email,
    };
    await axios
      .post(`${url}/users/addnewaccount`, body)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("email", user.email);
          sessionStorage.setItem("userId", user.username);
          toast.success(response.data);
          axios
            .post(`${url}/login`, {
              username: user.email,
              password: user.password,
            })
            .then((response) => {
              console.log(response);
              setLoading(false);
              console.log(response.headers.authorization);
              sessionStorage.setItem(
                "accountItem",
                response.headers.authorization
              );
              dispatch(signUp(response.headers.authorization));
              if (type === "Personal") {
                navigate("/account/Personal/Profile");
                window.location.reload();
              } else {
                navigate("/account/Merchant/Profile");
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log(err.response);
              toast.error(err.response);
            });
        } else {
          setLoading(false);
          toast.error("An error occured!");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response.data);
      });
  };

  const goBack = () => {
    if (position > 0) {
      setPosition(position - 1);
      setUser({ ...user, token: "", password: "", confirmPassword: "" });
      setAgree(false);
      setToken("");
    }
  };
  return (
    <div>
      {position > 0 && (
        <div className="go__back" onClick={goBack}>
          <i className="fas fa-long-arrow-alt-left"></i> Go Back
        </div>
      )}
      {/* {type} */}
      <div className="form__head">
        <h3>Verify OTP</h3>
        <p>Enter the token sent to {user.email}</p>
        {/* {token} */}
      </div>
      <div className="form__group">
        <input
          type="text"
          name="token"
          placeholder="OTP"
          value={user.token}
          onChange={(e) => setUser({ ...user, token: e.target.value })}
        />
      </div>
      <div className="agree__area">
        <input
          type="checkbox"
          name="agree"
          value={agree}
          onChange={() => setAgree(!agree)}
        />{" "}
        By clicking on this box, you agree to ROHIPAY's terms and conditions and
        it's privacy policies.
      </div>
      <div className="form__group">
        <button onClick={handleSubmit}>
          {!loading ? (
            "Verify OTP"
          ) : (
            <ReactLoading type="spin" color="#fff" width={30} height={30} />
          )}
        </button>
      </div>
    </div>
  );
};

export default Token;
