import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";
import { uploadRequiredDocuments } from "../../../app/userReducer/actions";

const Settings = () => {
  // eslint-disable-next-line
  const { profile, docsRequired, accounts } = useSelector(
    (state) => state.user
  );
  const { loading } = useSelector((state) => state.utilities);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [requiredDocs, setRequiredDocs] = useState([]);
  const [idCard, setIdCard] = useState(null);
  const [proofOfAddress, setProofOfAddress] = useState(null);
  const [documentationRequired, setDocumentationRequired] = useState([]);
  const dispatch = useDispatch();
  console.log(accounts[0]);
  console.log(documentationRequired);
  console.log(requiredDocs);

  useEffect(() => {
    if (accounts.length > 0) {
      const filteredDocumentation = accounts[0].documentationRequired.filter(
        (document) => document.status !== "Deleted"
      );
      setDocumentationRequired(filteredDocumentation);
    }
  }, [accounts]);

  // Validate input files
  useEffect(() => {
    if (idCard) {
      // console.log(idCard)
      if (idCard.type === "image/jpeg" || idCard.type === "image/png") {
        return;
      } else {
        setIdCard(null);
        toast.error("Only jpegs and png file types are allowed");
      }
    }
  }, [idCard]);

  useEffect(() => {
    if (proofOfAddress) {
      console.log(proofOfAddress);
      if (
        proofOfAddress.type === "application/pdf" ||
        proofOfAddress.type === "image/jpeg" ||
        proofOfAddress.type === "image/png"
      ) {
        return;
      } else {
        setProofOfAddress(null);
        toast.error("Only pdf/jpeg/png file types are allowed");
        return;
      }
    }
  }, [proofOfAddress]);

  useEffect(() => {
    if (docsRequired.length > 0) {
      const newDocsRequired = docsRequired.filter(
        (doc) => doc.accountType === user.profiletype
      );
      setRequiredDocs(newDocsRequired);
    }
  }, [docsRequired, user.profiletype]);

  const handleUploadIdCard = () => {
    if (!idCard) {
      toast.error("Incomplete ID Card details");
      return;
    }
    if (idCard.type === "image/jpeg" || idCard.type === "image/png") {
      const formData = new FormData();
      formData.append("file", idCard);
      formData.append("upload_preset", "y8frumqb");

      fetch("https://api.cloudinary.com/v1_1/dl8s7ivje/upload", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((response) => {
          console.log(response);
          dispatch(
            uploadRequiredDocuments({
              accountnumber: accounts[0].accountNumber,
              description: "ID Card",
              status: "Pending",
              type: "JPEG",
              value: response.secure_url,
            })
          );
        })
        .catch((err) => console.log(err.response));
    } else {
      toast.error("Only jpegs and png file types are allowed");
    }
  };

  const handleUploadProofOfAddress = () => {
    if (!proofOfAddress) {
      toast.error("Please upload a proof of address");
      return;
    }
    const formData = new FormData();
    formData.append("file", proofOfAddress);
    formData.append("upload_preset", "y8frumqb");

    fetch("https://api.cloudinary.com/v1_1/dl8s7ivje/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        dispatch(
          uploadRequiredDocuments({
            accountnumber: accounts[0].accountNumber,
            description: "Proof of Address",
            status: "Pending",
            type: "PDF",
            value: response.secure_url,
          })
        );
      })
      .catch((err) => console.log(err.response));
  };
  return (
    <>
      {user.profiletype === "Personal" && (
        <div className="account__settings">
          <div className="settings__cards">
            {/* Email Card */}
            <h3>Account Settings</h3>
            <Accordion defaultActiveKey={0}>
              {/* <div className="account__card mb-2">
            <Accordion.Item eventKey={0}>
              
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Email Settings</h5>
                    <p>{profile && profile.email}</p>
                  </div>
                </div>
              </Accordion.Header>
              
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </div> */}
              <div className="account__card mb-2">
                <Accordion.Item eventKey={0}>
                  {/* Accordion Header */}
                  <Accordion.Header>
                    <div className="settings__card">
                      <div className="settings__icon">
                        <i className="fas fa-key"></i>
                      </div>
                      <div className="settings__action">
                        <h5>Change Pin</h5>
                        <p>Change your account pin here</p>
                      </div>
                    </div>
                  </Accordion.Header>
                  {/* Accordion Body */}
                  <Accordion.Body></Accordion.Body>
                </Accordion.Item>
              </div>
              <div className="account__card mb-2">
                <Accordion.Item eventKey={1}>
                  {/* Accordion Header */}
                  <Accordion.Header>
                    <div className="settings__card">
                      <div className="settings__icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="settings__action">
                        <h5>Change Passwords</h5>
                        <p>**************</p>
                      </div>
                    </div>
                  </Accordion.Header>
                  {/* Accordion Body */}
                  <Accordion.Body></Accordion.Body>
                </Accordion.Item>
              </div>
              <h3 className="mt-4 mb-3">Account Verification</h3>
              <div className="account__card">
                <Accordion.Item eventKey={2}>
                  {/* Accordion Header */}
                  <Accordion.Header>
                    <div className="settings__card">
                      <div className="settings__icon">
                        <i className="fas fa-tasks"></i>
                      </div>
                      <div className="settings__action">
                        <h5>Verify Account</h5>
                        <p>
                          Document upload stages ({documentationRequired.length}
                          /{requiredDocs.length})
                        </p>
                      </div>
                    </div>
                  </Accordion.Header>
                  {/* Accordion Body */}
                  <Accordion.Body>
                    <div className="settings__body">
                      {requiredDocs.length > 0 ? (
                        <div className="account__requirements">
                          {documentationRequired.length > 0 ? (
                            documentationRequired.map((document, index) => (
                              <div key={index}>
                                {document.description !==
                                  requiredDocs[0].docsName && (
                                  <div className="id__card">
                                    <h5>1. {requiredDocs[0].docsName}</h5>
                                    <div className="form__group">
                                      <input
                                        type="file"
                                        name="ID Card"
                                        onChange={(e) =>
                                          setIdCard(e.target.files[0])
                                        }
                                      />
                                      <span className="file__type">
                                        ({requiredDocs[0].docsType} Only)
                                      </span>
                                    </div>
                                    <button onClick={handleUploadIdCard}>
                                      {loading ? (
                                        <ReactLoading
                                          color="#000e5e"
                                          width={30}
                                          height={30}
                                          type="spin"
                                        />
                                      ) : (
                                        "Upload ID Card"
                                      )}
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="id__card">
                              <h5>1. {requiredDocs[0].docsName}</h5>
                              <div className="form__group">
                                <input
                                  type="file"
                                  name="ID Card"
                                  onChange={(e) => setIdCard(e.target.files[0])}
                                />
                                <span className="file__type">
                                  ({requiredDocs[0].docsType} Only)
                                </span>
                              </div>
                              <button onClick={handleUploadIdCard}>
                                {loading ? (
                                  <ReactLoading
                                    color="#000e5e"
                                    width={30}
                                    height={30}
                                    type="spin"
                                  />
                                ) : (
                                  "Upload ID Card"
                                )}
                              </button>
                            </div>
                          )}
                          <div className="selfie mt-3">
                            <h5>{requiredDocs[1].docsName}</h5>
                            <button>Click to take Selfie</button>
                          </div>
                          <div className="proof__ofAddress mt-3">
                            <h5>{requiredDocs[2].docsName}</h5>
                            <div className="form__group">
                              <input
                                type="file"
                                name="Proof of Address"
                                onChange={(e) =>
                                  setProofOfAddress(e.target.files[0])
                                }
                              />
                              <span className="file__type">
                                ({requiredDocs[2].docsType}/JPEG/PNG Only)
                              </span>
                            </div>
                            <button onClick={handleUploadProofOfAddress}>
                              Upload Proof of Address
                            </button>
                          </div>
                        </div>
                      ) : (
                        <ReactLoading
                          color="#0a53be"
                          width={30}
                          height={30}
                          type="spin"
                        />
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <h3 className="mt-4 mb-3">Delete Account</h3>
              <div className="account__card">
                <Accordion.Item eventKey={3}>
                  {/* Accordion Header */}
                  <Accordion.Header>
                    <div className="settings__card">
                      <div className="settings__icon">
                        <i className="fas fa-ban"></i>
                      </div>
                      <div className="settings__action">
                        <h5>Delete Account</h5>
                        <p>We'll be sad to see you go 😞</p>
                      </div>
                    </div>
                  </Accordion.Header>
                  {/* Accordion Body */}
                  <Accordion.Body></Accordion.Body>
                </Accordion.Item>
              </div>
            </Accordion>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Settings;
