import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../../app/userReducer/actions";
import EmptyTransactions from "../Reusables/EmptyTransactions";
import TransactionsTable from "../Reusables/TransactionsTable";
import { Table } from "react-bootstrap";

const Transactions = () => {
  const { accounts, transactions } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [rearrangedTransactions, setRearrangedTransactions] = useState([]);
  console.log(transactions);

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(getTransactions(accounts[0].accountNumber));
    }
    // eslint-disable-next-line
  }, [accounts]);

  // Rearrange transactions
  useEffect(() => {
    if (transactions.length > 0) {
      if (rearrangedTransactions.length === 0) {
        rearrangedTransactions.push(transactions[0]);
      }
      // eslint-disable-next-line
      rearrangedTransactions.forEach((rearrangedTransaction) => {
        if (transactions.length > 0) {
          transactions.forEach((transaction) => {
            if (rearrangedTransaction.referenceId !== transaction.referenceId) {
              rearrangedTransactions.push(transaction)
            }
          });
        }
      });
    }

    // setRearrangedTransactions(newTransactions);
    // eslint-disable-next-line
  }, [transactions]);
  console.log(rearrangedTransactions);

  return (
    <div className="transactions__section shadow m-2 bg-body rounded">
      {transactions.length > 0 ? (
        <>
          <h3>Recent Transactions</h3>
          <Table className="mt-5">
            <thead>
              <tr>
                <th></th>
                <th>Source Account Number</th>
                <th>Source Account Name</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            {transactions.map((transaction) => (
              <TransactionsTable
                transaction={transaction}
                accounts={accounts[0]}
              />
            ))}
          </Table>
        </>
      ) : (
        <EmptyTransactions />
      )}
    </div>
  );
};

export default Transactions;
