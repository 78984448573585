import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../app/userReducer/actions";

import Logo from "../../images/Logo15.png";

const Sidebar = ({ sidebarOpen, toggleSidebar, closeSideBar }) => {
  const [activeDiv, setActive] = useState("Dashboard");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : "";
  // console.log(pathname);

  const logout = () => {
    dispatch(logoutUser());
    window.location.reload();
  };

  const handleActiveDiv = (name) => {
    setActive(name);
    closeSideBar();
  };
  const classDiv = (name) => {
    if (name === activeDiv) {
      return "active";
    } else {
      return "";
    }
  };

  // Check for route paths and persist active Div
  useEffect(() => {
    switch (pathname) {
      case "/dashboard":
        setActive("Dashboard");
        break;
      case "/dashboard/transfer":
        setActive("Transfers");
        break;
      case "/dashboard/receive-payments":
        setActive("Receive Payments");
        break;
      case "/dashboard/transactions":
        setActive("Transactions");
        break;
      case "/dashboard/account":
        setActive("Account");
        break;
      case "/dashboard/teams":
        setActive("Teams");
        break;
      default:
        setActive("");
    }
  }, [pathname]);
  return (
    <div
      className={sidebarOpen ? "sidebar__responsive" : ""}
      id="sidebar__section"
    >
      <div className="sidebar__icon">
        <i className="fas fa-times" onClick={toggleSidebar}></i>
      </div>
      <div className="sidebar__logo">
        <Link to="/">
          <img src={Logo} alt="Rohipay Logo" onClick={toggleSidebar} />
        </Link>
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__links">
          <ul>
            <Link to="/dashboard">
              <li
                className={classDiv("Dashboard")}
                onClick={() => handleActiveDiv("Dashboard")}
              >
                <i className="fas fa-th-list"></i> Dashboard
              </li>
            </Link>
            {/* {user && user.userrole[0].apriviledge === "OWNER" && ( */}
              <Link to="/dashboard/transfer">
                <li
                  className={classDiv("Transfers")}
                  onClick={() => handleActiveDiv("Transfers")}
                >
                  <i className="fas fa-layer-group"></i> Transfers
                </li>
              </Link>
            

            {user && user.profiletype === "Merchant" && (
              <Link to="/dashboard/receive-payments">
                <li
                  onClick={() => handleActiveDiv("Receive Payments")}
                  className={classDiv("Receive Payments")}
                >
                  <i className="fas fa-layer-group"></i> Receive Payments
                </li>
              </Link>
            )}
            <Link to="/dashboard/transactions">
              <li
                onClick={() => handleActiveDiv("Transactions")}
                className={classDiv("Transactions")}
              >
                <i className="fas fa-bars"></i> Transactions
              </li>
            </Link>
            <Link to="/dashboard/account">
              <li
                onClick={() => handleActiveDiv("Account")}
                className={classDiv("Account")}
              >
                <i className="fas fa-user-cog"></i> Account
              </li>
            </Link>
            {user && user.profiletype === "Merchant" && (
              <Link to="/dashboard/teams">
                <li
                  onClick={() => handleActiveDiv("Teams")}
                  className={classDiv("Teams")}
                >
                  <i className="fas fa-users"></i> Teams
                </li>
              </Link>
            )}

            <li onClick={logout} style={{ cursor: "pointer" }}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </li>
          </ul>
        </div>

        {/* <div className="sidebar__copyright">
          <h3>Rohipay.</h3>
          <p>
            All rights reserved. Copyright &copy; 2021,{" "}
            
            <a target="_blank" href="https://www.bethedigitech.com">
              betheldigitech.com
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
