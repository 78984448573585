import React from "react";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";

const PersonalSettings = () => {
  const { personal } = useSelector((state) => state.personal);
  console.log(personal);
  return (
    <div className="account__settings">
      <div className="settings__cards">
        {/* Email Card */}
        <h3>Account Settings</h3>
        <Accordion defaultActiveKey={0}>
          <div className="account__card mb-2">
            <Accordion.Item eventKey={0}>
              {/* Accordion Header */}
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Email Settings</h5>
                    <p>{personal.email}</p>
                  </div>
                </div>
              </Accordion.Header>
              {/* Accordion Body */}
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </div>
          <div className="account__card mb-2">
            <Accordion.Item eventKey={4}>
              {/* Accordion Header */}
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                  <i className="fas fa-key"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Change Pin</h5>
                    <p>Change your account pin here</p>
                  </div>
                </div>
              </Accordion.Header>
              {/* Accordion Body */}
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </div>
          <div className="account__card mb-2">
            <Accordion.Item eventKey={1}>
              {/* Accordion Header */}
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Change Passwords</h5>
                    <p>**************</p>
                  </div>
                </div>
              </Accordion.Header>
              {/* Accordion Body */}
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </div>
          <h3 className="mt-4 mb-3">Account Verification</h3>
          <div className="account__card">
            <Accordion.Item eventKey={2}>
              {/* Accordion Header */}
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-tasks"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Verify Account</h5>
                    <p>View stages of account verification</p>
                  </div>
                </div>
              </Accordion.Header>
              {/* Accordion Body */}
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </div>
          <h3 className="mt-4 mb-3">Delete Account</h3>
          <div className="account__card">
            <Accordion.Item eventKey={3}>
              {/* Accordion Header */}
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-ban"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Delete Account</h5>
                    <p>We'll be sad to you go 😞</p>
                  </div>
                </div>
              </Accordion.Header>
              {/* Accordion Body */}
              <Accordion.Body></Accordion.Body>
            </Accordion.Item>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default PersonalSettings;
