import React from "react";
// import Index from '../../components/MerchantSteps/Index'
import Navbar from "../../components/Navbar";
import Index from "../../components/Reusables/Merchant/Index";

const MerchantSignUp = () => {
  return (
    <div>
      <Navbar />
      {/* <Index /> */}
      <div className="">
        <Index
          title={"Merchant Account Information"}
          secondTitle={"Account Handler Information"}
        />
      </div>
    </div>
  );
};

export default MerchantSignUp;
