import React from "react";
// import Index from '../../components/BusinessSteps/Index'
import Navbar from "../../components/Navbar";
// import Index from "../../components/Reusables/Business/Index";

const BusinessSignUp = () => {
  return (
    <div>
      <Navbar />
      <div className="shadow text-center m-5 p-5">
        {/* <Index
          title={"Business Information"}
          secondTitle="Business Account Handler Information"
        /> */}
        <h3>Business account coming up soon</h3>
      </div>
    </div>
  );
};

export default BusinessSignUp;
