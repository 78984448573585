import React from "react";
import EmptyTransactions from "../../../components/Reusables/EmptyTransactions";

const MerchantTransactions = () => {
  return (
    <div className="transactions">
      <h3>Transactions</h3>
      <EmptyTransactions />
    </div>
  );
};

export default MerchantTransactions;
