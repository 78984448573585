import React, { Suspense } from "react";
// import { useDispatch, useSelector } from "react-redux";
import "./App.css";
// import WaitList from "./components/WaitList/WaitList";
// import WaitListNavbar from "./components/WaitList/WaitListNavbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { changeState } from "./app/accountReducer/actions";
// import Navbar from "./components/Navbar";
import Personal from "./views/Personal";
import Business from "./views/Business";
import Merchant from "./views/Merchant";
import Options from "./views/Options";
import PersonalSignUp from "./views/Auth/PersonalSignUp";
import Login from "./views/Auth/Login";


import ForgotPassword from "./views/Auth/ForgotPassword";
import BusinessSignUp from "./views/Auth/BusinessSignUp";
import MerchantSignUp from "./views/Auth/MerchantSignUp";
// import SignUp from "./views/Auth/SignUp";

// Dashboard
import Index from "./views/Dashboard/Index";

// React toastify css
import "react-toastify/dist/ReactToastify.css";

// Styles
import "./styles/Auth/Auth.css";
import "./styles/Dashboard/Dashboard.css";
import "./styles/Components/Components.css";
import "./styles/landing.css";
import "./styles/PersonalAccount/Personal.css";
import './styles/Merchant/Merchant.css'
import './styles/Dashboard/Overview.css'

// Dashboard Components
import Auth from "./views/Authorization/Auth";
import Private from "./views/Authorization/Private";
import Onboarded from "./views/Onboarded";
import Landing from "./views/Landing";

// Personal Dashboard Components
// import Overview from "./views/Dashboard/PersonalDashboard/Overview";
import Transfers from "./views/Dashboard/Transfers";
// import PersonalAccount from "./views/Dashboard/PersonalDashboard/PersonalAccount";
import PersonalAccounts from "./views/Dashboard/PersonalDashboard/PersonalAccounts";
import Customers from "./views/Auth/Customers";
import AccountForm from "./views/Dashboard/PersonalDashboard/AccountForm";
import PersonalSettings from "./views/Dashboard/PersonalDashboard/PersonalSettings";
import MerchantProfile from "./views/Dashboard/MerchantDashboard/MerchantProfile";
import MerchantAlt from "./components/Reusables/Merchant/MerchantAlt";
import PersonalSignUpAlt from "./components/PersonalComponents/PersonalSignUpAlt";
import MerchantReceivePayments from "./views/Dashboard/MerchantDashboard/MerchantReceivePayments";
import MerchantTransactions from "./views/Dashboard/MerchantDashboard/MerchantTransactions";
import Loading from "./components/Reusables/Loading";
import MerchantQRPay from "./views/Dashboard/MerchantDashboard/MerchantQRPay";
import MerchantStep from "./components/Reusables/Merchant/MerchantStep";
// import PersonalTransactions from "./views/Dashboard/PersonalDashboard/PersonalTransactions";
import MerchantTeams from './views/Dashboard/MerchantDashboard/MerchantTeams'
import AddMerchantTeam from "./views/Dashboard/MerchantDashboard/AddMerchantTeam";
import OverviewNew from "./views/Dashboard/RestructureDashboard/OverviewNew";
import Account from "./views/Dashboard/RestructureDashboard/Account";
import Settings from "./views/Dashboard/RestructureDashboard/Settings";
import AllProfile from './views/Dashboard/RestructureDashboard/AllProfile'
import Transactions from './components/Dashboard/Transactions'

// const Overview = React.lazy(() =>
//   import("./views/Dashboard/PersonalDashboard/Overview")
// );dhasboard

function App() {
  // const token = localStorage.getItem("token");
  // const email = localStorage.getItem("email");
  
  // eslint-disable
  // const { profile } = useSelector((state) => state.accountType);

  
  return (
    <div className="App">
      {/* <WaitListNavbar />
      <WaitList /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/business" element={<Business />} />
          <Route path="/merchant" element={<Merchant />} />
          <Route path="/options" element={<Options />} />
          <Route element={<Auth />}>
            {/* <Route path="/onboarding" element={<SignUp />} /> */}
            <Route path="/account/Personal" element={<PersonalSignUp />} />
            <Route path="/account/Business" element={<BusinessSignUp />} />
            <Route path="/account/Merchant" element={<MerchantSignUp />} />
            <Route path="/account/Personal/Profile" element={<Customers />} />
            <Route
              path="/account/Merchant/Profile"
              element={<MerchantStep />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/success" element={<Onboarded />} />
          </Route>
          {/* Dashboard */}
          <Route element={<Private />}>
            <Route path="/dashboard" element={<Index />}>
              <Route
                path=""
                element={
                  <Suspense fallback={<Loading />}>
                    <OverviewNew />
                  </Suspense>
                }
              />
              <Route path="transfer" element={<Transfers />} />
              {/* Personal Routes */}
              <Route path="personal/account" element={<PersonalAccounts />} />
              <Route path="personal/account-form" element={<AccountForm />} />
              
              <Route path="create-personal" element={<PersonalSignUpAlt />} />
              <Route
                path="personal/settings"
                element={
                  <Suspense fallback={<Loading />}>
                    <PersonalSettings />
                  </Suspense>
                }
              />
              <Route
                path="transactions"
                element={
                  <Suspense fallback={<Loading />}>
                    <Transactions />
                  </Suspense>
                }
              />
              {/* Merchant Routes */}
              <Route path="merchant/profile" element={<MerchantProfile />} />
              <Route path="create-merchant" element={<MerchantAlt />} />
              <Route
                path="receive-payments"
                element={<MerchantReceivePayments />}
              />
              <Route
                path="merchant/transactions"
                element={<MerchantTransactions />}
              />
              <Route path="merchant/:id" element={<MerchantQRPay />} />
              <Route path="teams" element={<Suspense fallback={<Loading />}>
                <MerchantTeams />
              </Suspense>} />
              <Route path="add/team-member" element={<AddMerchantTeam ></AddMerchantTeam>} />
              <Route path="account" element={<Account />} />
              <Route path="settings" element={<Settings/>} />
              <Route path="profile" element={<AllProfile />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
