import React, { useState } from "react";
import axios from "axios";
import { url } from "../../lib/links";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";

const token = sessionStorage.getItem("token");

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const TransactionSuccessful = () => {
  return (
    <div className="transaction__successful shadow mt-5 mb-4">
      <i className="fas fa-check-circle"></i>
      <h3>Transaction Successful</h3>
    </div>
  )
}

const PayWithEmail = ({ accounts }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [payee, setPayee] = useState("");
  const [showFirst, setShowFirst] = useState(true);
  const [showPayee, setShowPayee] = useState(false);
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [paymentResponse, setPaymentResponse] = useState({});
  const [showPin, setShowPin] = useState("");
  const [pin, setPin] = useState("");
  const [showSuccessful, setShowSuccessful] = useState(false)

  const handleVerifyEmail = async () => {
    setLoading(true);
    await axios
      .post(`${url}/accounts/getAllAccountsDTOByEmail`, { id: email })
      .then((response) => {
        if (response.data) {
          console.log(response.data[0]);
          setPayee(response.data[0]);
          setLoading(false);
          setShowFirst(false);
          setShowPayee(true);
        } else {
          setLoading(false);
          toast.error("No user found");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  console.log(accounts[0]);
  const handlePayment = () => {
    if (amount > accounts[0].availableBalance) {
      toast.error("Insiffucient Balance");
      return;
    }
    if (accounts[0].availableBalance - amount < 0) {
      toast.error("Insiffucient Balance");
      return;
    }
    setLoading(true);
    axios
      .post(`${url}/accounts/createSingleTransferToBankDTO`, {
        auth: {
          secure: ["otp"],
          type: "",
        },
        requestRef: "",
        requestType: "",
        statusWebHook: "",
        transaction: {
          amount: amount,
          channel: "web",
          customer: {
            accountNumber: [accounts[0].accountNumber],
            email: accounts[0].email,
            firstname: accounts[0].accountName,
            mobileNumber: "",
            sourceAccount: accounts[0].accountNumber,
            sourceAccountName: accounts[0].accountName,
            sourceCurrency: accounts[0].currency,
            surname: "",
          },
          destinationAccount: payee.accountNumber,
          destinationAccountName: payee.accountName,
          destinationBankCode: "",
          destinationBankName: "Rohipay",
          destinationCurrency: payee.currency,
          destinationNarration: description,
          latitude: 0,
          longitude: 0,
          transactionDescription: description,
          transactionRef: "",
          transactionRefParent: "",
        },
        type: "",
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setPaymentResponse(response.data);
        setShowPayee(false);
        setShowPin(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response);
      });
  };

  const handleVerifyPin = async () => {
    if (!pin) {
      toast.error("Please input your account pin");
      return;
    }
    console.log({
      accountNumber: accounts[0].accountNumber,
      pin: pin.toString(),
    });
    setLoading(true);
    await axios
      .post(`${url}/accounts/verifyAccountPin`, {
        accountNumber: accounts[0].accountNumber,
        pin: pin,
      })
      .then((response) => {
        // setLoading(false);
        if (response.data) {
          console.log(paymentResponse);
          axios
            .post(`${url}/accounts/TransactionCompletionOBADTO`, {
              authtype: [
                {
                  autId: 0,
                  type: "OTP",
                  value: paymentResponse.data.authDetails.label,
                },
              ],
              requestRef: paymentResponse.data.transactionRef,
              status: "Done",
              transactionRef: paymentResponse.data.transactionRef,
            })
            .then((response) => {
              setLoading(false);
              console.log(response.data);
              toast.success(response.data.message);
              setShowSuccessful(true)
            })
            .catch((err) => {
              setLoading(false)
              console.log(err.response);
            });
        }
        console.log(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response.data);
      });
  };
  return (
    <>
      {!showSuccessful && <div className="pay__withEmail shadow p-3 m-3">
        {showFirst && (
          <div className="first__state">
            <h3 className="text-center">Send money via email</h3>
            <div className="form__group">
              <input
                type="email"
                name="email"
                placeholder="Enter recipient's rohipay email"
                className="form__control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form__group">
              {!loading ? (
                <button onClick={handleVerifyEmail}>
                  Verify Email and Pay
                </button>
              ) : (
                <ReactLoading
                  color="#000e5e"
                  type="spin"
                  width={30}
                  height={30}
                />
              )}
            </div>
          </div>
        )}
        {showPayee && payee && (
          <div className="mt-5">
            <div className="form__group">
              <input
                type="text"
                className="form__control"
                placeholder="Amount to be transfered"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="form__group">
              <input
                type="text"
                value={payee.accountName ? payee.accountName : ""}
                disabled
              />
            </div>
            <div className="form__group">
              <input
                type="text"
                value={payee.accountNumber ? payee.accountNumber : ""}
                disabled
              />
            </div>
            <div className="form__group">
              <input
                type="text"
                value={payee.currency ? payee.currency : ""}
                disabled
              />
            </div>
            <div className="form__group">
              <input
                type="text"
                name="description"
                placeholder="Transaction Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="form__group">
              {amount > 0 && description && (
                <button onClick={handlePayment}>
                  {!loading ? (
                    "Pay"
                  ) : (
                    <ReactLoading
                      color="#000e5e;"
                      type="spin"
                      width={30}
                      height={30}
                    />
                  )}
                </button>
              )}
            </div>
          </div>
        )}

        {showPin && (
          <div className="mt-3">
            <h3>Confirm Account Pin</h3>
            <div className="form__group">
              <input
                type="text"
                name="pin"
                placeholder="Account Pin"
                className="form__control"
                value={pin}
                onChange={(e) => {
                  setPin(e.target.value);
                }}
              />
            </div>
            <div className="form__group">
              <button onClick={handleVerifyPin}>
                {!loading ? (
                  "Confirm Pin"
                ) : (
                  <ReactLoading
                    color="#000e5e"
                    type="spin"
                    width={30}
                    height={30}
                  />
                )}
              </button>
            </div>
          </div>
        )}
      </div>}
      {showSuccessful && <TransactionSuccessful />}
      <ToastContainer />
    </>
  );
};

export default PayWithEmail;
