import React, { useState } from "react";
import Navbar from "../../components/Navbar";
// import Index from "../../components/PersonalMultistep/Index";
import "../../styles/Auth/Auth.css";

// eslint-disable-next-line
import { toast, ToastContainer } from "react-toastify";

// Utils

import Personal from "../../components/Reusables/Personal";
import Token from "../../components/Reusables/Token";

const PersonalSignUp = () => {
  //eslint-disable-next-line
  const [showSignUp, setShowSignUp] = useState(true);
  //eslint-disable-next-line
  const [showAccount, setShowAccount] = useState(false);
  const [position, setPosition] = useState(0);
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
  });
  const [agree, setAgree] = useState(false);

  // console.log(loading, success);
  // const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");

  const steps = [
    {
      name: "Credentials",
      component: (
        <Personal
          user={user}
          setUser={setUser}
          position={position}
          setPosition={setPosition}
          title={"Personal"}
          setToken={setToken}
        />
      ),
    },
    {
      name: "Token",
      component: (
        <Token
          user={user}
          setUser={setUser}
          agree={agree}
          setAgree={setAgree}
          position={position}
          setPosition={setPosition}
          setShowSignUp={setShowSignUp}
          type="Personal"
          token={token}
          setToken={setToken}
        />
      ),
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="personal__signup bg-light">
        {/* <Index /> */}
        {showSignUp && <>{steps[position].component}</>}
      </div>
      <ToastContainer />
    </div>
  );
};

export default PersonalSignUp;
