const initialState = {
  profile: "",
  personalAccount: [],
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_ACCOUNT":
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};
