import React, { useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../images/Logo15.png";
import "../styles/Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../app/userReducer/actions";

const Navbar = () => {
  // eslint-disable-next-line
  const [activeLink, setActiveLink] = useState(null);

  // Get State
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // Links
  // const mainLinks = [
  //   { name: "Personal", link: "/" },
  //   { name: "Business", link: "/business" },
  //   { name: "Merchant", link: "/merchant" },
  // ];
  // const secondLinks = [
  //   { name: "Account", link: "/" },
  //   { name: "Send Money", link: "/" },
  //   { name: "Help", link: "/help" },
  // ];
  // Apply Active Class
  return (
    <div>
      {/* <!-- Navbar--> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Rohi Logo Avatar" width={50} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/personal">
                  Personal
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/merchant">
                  Merchant
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/business">
                  Business
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              {!isLoggedIn && (
                <>
                  <li className="nav-item">
                    <Link to="/options" className="nav-link">
                      Sign Up
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/login">
                      <button className="nav-link login__button">Login</button>
                    </Link>
                  </li>
                </>
              )}
              {isLoggedIn && (
                <>
                  <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link login__button"
                      onClick={() => {
                        dispatch(logoutUser());
                        window.location.reload();
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {/* <!-- Navbar --> */}
    </div>
  );
};

export default Navbar;
