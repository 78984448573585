import React from "react";

const PersonalBusiness = ({ secondTitle, business, setBusiness }) => {
  return (
    <div>
      <h3>{secondTitle}</h3>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="first name"
              placeholder="First Name"
              value={business.firstName}
              onChange={(e) =>
                setBusiness({ ...business, firstName: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="last name"
              placeholder="Last Name"
              value={business.lastName}
              onChange={(e) =>
                setBusiness({ ...business, lastName: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="form__group">
        <label htmlFor="date">Date of Birth</label>
        <input
          type="date"
          name="date of birth"
          value={business.dateOfBirth}
          onChange={(e) =>
            setBusiness({ ...business, dateOfBirth: e.target.value })
          }
        />
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="street"
              placeholder="Street"
              value={business.street}
              onChange={(e) =>
                setBusiness({ ...business, street: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="building"
              placeholder="Building"
              value={business.building}
              onChange={(e) =>
                setBusiness({ ...business, building: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="region"
              placeholder="Region (Optional)"
              value={business.region}
              onChange={(e) =>
                setBusiness({ ...business, region: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="form__group">
            <input
              type="text"
              name="town/city"
              placeholder="Town/City"
              value={business.town}
              onChange={(e) =>
                setBusiness({ ...business, town: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="form__group">
        <input
          type="text"
          name="Postal Code"
          placeholder="Post Code"
          value={business.postCode}
          onChange={(e) =>
            setBusiness({ ...business, postCode: e.target.value })
          }
        />
      </div>
    </div>
  );
};

export default PersonalBusiness;
