import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const Auth = () => {
    const state = useSelector(state => state.user)
    return !state.isLoggedIn ? <Outlet /> : <Navigate to="/dashboard" />
}

export default Auth
