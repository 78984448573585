import React, { useState } from "react";
import { useSelector } from "react-redux";
import PayWithEmail from "../../components/Dashboard/PayWithEmail";
import PayWithPhone from "../../components/Dashboard/PayWithPhone";
import ScanAndPay from "../../components/Dashboard/ScanAndPay";
import TransferToBank from "../../components/Dashboard/TransferToBank";

const Transfers = () => {
  // eslint-disable-next-line
  const { accounts } = useSelector((state) => state.user);
  const [activeDiv, setActiveDiv] = useState(null);
  // eslint-disable-next-line
  const [transferActions, setTransferActions] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showScan, setShowScan] = useState(false);
  const [showBank, setShowBank] = useState(false);

  const handleShowEmail = (div) => {
    setTransferActions(false);
    setShowEmail(true);
    setShowPhone(false);
    setShowScan(false);
    setShowBank(false);
    setActiveDiv(div);
  };
  // eslint-disable-next-line
  const handleShowPhone = (div) => {
    setTransferActions(false);
    setShowEmail(false);
    setShowPhone(true);
    setShowScan(false);
    setShowBank(false);
    setActiveDiv(div);
  };
  const handleShowBank = (div) => {
    setTransferActions(false);
    setShowEmail(false);
    setShowPhone(false);
    setShowScan(false);
    setShowBank(true);
    setActiveDiv(div);
  };
  const handleShowScan = (div) => {
    setTransferActions(false);
    setShowEmail(false);
    setShowPhone(false);
    setShowScan(true);
    setShowBank(false);
    setActiveDiv(div);
  };

  // eslint-disable-next-line
  const goBack = () => {
    setTransferActions(true);
    setShowEmail(false);
    setShowPhone(false);
    setShowScan(false);
    setShowBank(false);
  };

  const handleChangeDiv = (div) => {
    setActiveDiv(div);
  };

  const handleActiveClass = (div) => {
    if (div === activeDiv) {
      return `active__button`;
    } else {
      return ``;
    }
  };
  return (
    <>
      <div className="transfer__section shadow p-3 m-3">
        <h3>Transfer Funds</h3>

        <div className="transfer__from">
          {/* <p>Transfer From</p> */}
          {/* <select name="currency">
            {personal.personalcurrencies &&
              personal.personalcurrencies.map((currency, index) => (
                <option
                  key={index + currency.currency}
                  value={currency.currency}
                >
                  {currency.currency}{" "}
                  {currency.cdef === "default" && "(Default Account)"}
                </option>
              ))}
          </select> */}
        </div>
        {/* {transferActions === false && (
            <div className="go__back m-3 p-3" onClick={goBack}>
              <i className="fas fa-long-arrow-alt-left"></i> Go Back
            </div>
          )} */}

        {/* Transfer options */}
        <div className="transfer__options">
          <button
            onClick={() => handleShowScan("Scan and Pay")}
            className={handleActiveClass("Scan and Pay")}
          >
            <i className="fas fa-qrcode"></i> Scan and Pay
          </button>
          <button
            onClick={() => handleShowEmail("Transfer With Email")}
            className={handleActiveClass("Transfer With Email")}
          >
            <i className="far fa-envelope"></i> Transfer With Email
          </button>
          {/* <button onClick={handleShowPhone}>
              <i className="fas fa-phone-square-alt"></i> Transfer with Phone
            </button> */}
          <button
            onClick={() => handleShowBank("Transfer to a Rohipay Account")}
            className={handleActiveClass("Transfer to a Rohipay Account")}
          >
            <i className="fas fa-university"></i> Transfer to a Rohipay Account
          </button>
          <button
            onClick={() => handleChangeDiv("Transfer to another Bank")}
            className={handleActiveClass("Transfer to another Bank")}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i className="fas fa-money-check-alt"></i> Transfer to another Bank
          </button>
        </div>

        {showEmail && <PayWithEmail accounts={accounts} />}
        {showPhone && <PayWithPhone />}
        {showBank && <TransferToBank />}
        {showScan && <ScanAndPay />}

        {/* Quick Transfer */}
        {/* <div className="quick__transfer">
          <h3>Quick Transfer</h3>
          <p>Your beneficiaries will appear here if you have them saved</p>
        </div> */}
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Transfer to another Bank
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Feature not available at the moment</p></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transfers;
