import { url } from "../../lib/links";
import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../store";

const token =
  sessionStorage.getItem("token") || sessionStorage.getItem("accountItem");
// eslint-disable-next-line
const email = sessionStorage.getItem("email");
const username = sessionStorage.getItem("userId");

let cancelToken;

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const signUp = (response) => {
  return (dispatch) => {
    console.log(response);
    dispatch({
      type: "SIGNUP_USER",
      payload: response,
    });
    dispatch({
      type: "SET_SESSION_TOKEN",
      payload: response,
    });
  };
};

export const signUpUser = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelling previous request");
    }
    cancelToken = axios.CancelToken.source();
    axios
      .post(`${url}/customers/createCustomersDTO`, data)
      .then((response) => {
        console.log(
          "Create customerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr: " +
            JSON.stringify(data)
        );
        dispatch({ type: "SET_LOADING_OFF" });
        dispatch({
          type: "SET_USERID",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: "SET_LOADING_OFF" });
      });

    console.log(store.getState().user.sessionToken);
  };
};

export const onboardUser = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/accounts/createAccountsDTO`, data)
      .then((response) => {
        console.log(response.data);
        // dispatch({ type: "SET_SUCCESS" });
        dispatch({ type: "SET_LOADING_OFF" });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: "SET_LOADING_OFF" });
      });
  };
};

// Assign responsibilities API
export const createRoles = (data) => {
  return (dispatch) => {
    axios
      .post(`${url}/accounts/addAccountHPriviledgesDTOByUserID`, data)
      .then((response) => {
        console.log(response.data);
        dispatch({ type: "ACCOUNT_SUCCESS" });
      });
  };
};

export const onboardMerchant = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/merchant/createMerchantDTO`, data)
      .then((response) => {
        console.log(response.data);
        dispatch({ type: "SET_LOADING_OFF" });
        dispatch({ type: "SET_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING_OFF" });
        console.log(err.response);
        toast.error(err.response.data);
      });
  };
};

export const createMerchant = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios.post(`${url}/merchant/createMerchantDTO2`, data).then((response) => {
      console.log(response.data);
      dispatch({ type: "SET_MERCHANT_SUCCESS" });
      dispatch({ type: "SET_LOADING_OFF" });
      dispatch({
        type: "SET_USERID",
        payload: response.data,
      });
    });
  };
};

export const userId = (response) => {
  return (dispatch) => {
    console.log(response);
    dispatch({
      type: "SET_USERID",
      payload: response,
    });
  };
};

// Logout action
export const logoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT_USER",
    });
  };
};

// Login action
export const loginUser = (response) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN_USER",
      payload: response,
    });
  };
};

// Get User's roles
export const getUserRoles = () => {
  return (dispatch) => {
    if (username) {
      axios
        .post(`${url}/accounts/getAccountHPriviledgesDTOByUserID`, {
          id: username,
        })
        .then((response) => {
          if (!sessionStorage.getItem("user")) {
            if (response.data[0].profiletype === "Personal") {
              dispatch({ type: "SET_LOADING_ON" });
              axios
                .post(`${url}/customers/getAllCustomersDTOByCustomerID`, {
                  id: response.data[0].profileid,
                })
                .then((response) => {
                  console.log(response.data);
                  dispatch({ type: "SET_PROFILES", payload: response.data });
                  dispatch({ type: "SET_LOADING_OFF" });
                })
                .catch((err) => {
                  console.log(err.response);
                });
            } else {
              dispatch({ type: "SET_LOADING_ON" });
              axios
                .post(`${url}/merchant/getMerchantDTOByMerchantId`, {
                  id: response.data[0].profileid,
                })
                .then((response) => {
                  console.log(response.data);
                  dispatch({ type: "SET_PROFILES", payload: response.data });
                  dispatch({ type: "SET_LOADING_OFF" });
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }
          } else {
            dispatch({ type: "SET_LOADING_OFF" });
          }
          dispatch({ type: "SET_PRIVILEGE", payload: response.data });
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };
};

export const getUserDetails = () => {
  return (dispatch) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      if (user.profiletype === "Personal") {
        dispatch({ type: "SET_LOADING_ON" });
        axios
          .post(`${url}/customers/getAllCustomersDTOByCustomerID`, {
            id: user.profileid,
          })
          .then((response) => {
            dispatch({ type: "SET_PROFILES", payload: response.data });
            dispatch({ type: "SET_LOADING_OFF" });
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        console.log(user.profiletype);
        dispatch({ type: "SET_LOADING_ON" });
        axios
          .post(`${url}/merchant/getMerchantDTOByMerchantId`, {
            id: user.profileid,
          })
          .then((response) => {
            console.log(response.data);
            dispatch({ type: "SET_PROFILES", payload: response.data });
            dispatch({ type: "SET_LOADING_OFF" });
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    }
  };
};

// Get user accounts
export const getUserAccounts = () => {
  return (dispatch) => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      if (user.profiletype === "Personal") {
        axios
          .post(`${url}/accounts/getAllAccountDetailssDTOByCustomerID`, {
            id: user.profileid,
          })
          .then((response) => {
            dispatch({ type: "SET_ACCOUNTS", payload: response.data });
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        axios
          .post(`${url}/accounts/getAllAccountDetailssDTOByMerchantID`, {
            id: user.profileid,
          })
          .then((response) => {
            console.log(response.data);
            dispatch({ type: "SET_ACCOUNTS", payload: response.data });
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    }
  };
};

// Get Transactions by Account Number
export const getTransactions = (data) => {
  return (dispatch) => {
    axios
      .post(`${url}/accounts/getAllBankTransferDTOsByAccountNo`, {
        id: data,
      })
      .then((response) => {
        console.log(response.data);
        dispatch({ type: "SET_TRANSACTIONS", payload: response.data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

// Update Profile Pics
export const uploadProfilePics = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/users/getprofilepics`, { profilepics: data })
      .then((response) => {
        dispatch({ type: "SET_LOADING_OFF" });
        console.log(response.data);
        dispatch(getProfilePics());
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: "SET_LOADING_OFF" });
      });
  };
};

// Get Profile Pics
export const getProfilePics = () => {
  return (dispatch) => {
    axios
      .post(`${url}/users/getprofilepics`)
      .then((response) => {
        // console.log(response.data);
        dispatch({ type: "SET_PROFILE_PICS", payload: response.data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

// Get required documents
export const getRequiredDocuments = () => {
  return (dispatch) => {
    axios
      .get(`${url}/accounts/getAllAccountRequirements`)
      .then((response) => {
        dispatch({ type: "SET_DOCS_REQUIRED", payload: response.data });
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

// Upload required documents
export const uploadRequiredDocuments = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/accounts/createDocumentationRequired`, data)
      .then((response) => {
        toast.success(response.data);
        dispatch({ type: "SET_LOADING_OFF" });
        // toast.success("Upload successful")
      })
      .catch((err) => console.log(err.response));
  };
};

// Delete required documents
export const deleteRequiredDocuments = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/accounts/deleteDocumentationRequired`, { id: data })
      .then((response) => {
        console.log(response);
        dispatch(getUserAccounts());
        dispatch({ type: "SET_LOADING_OFF" });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

// Get account privileges
export const getAccountPrivileges = () => {
  return (dispatch) => {
    axios
      .post(`${url}/accounts/getAccountPriviledgesDTO`)
      .then((response) => {
        console.log(response.data);
        dispatch({ type: "SET_USER_PRIVILEGES", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Add a team member
export const addTeamMember = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/accounts/addAccountHPriviledgesDTOByUserID`, data)
      .then((response) => {
        dispatch({ type: "SET_LOADING_OFF" });
        dispatch({ type: "SET_TEAM_MEMBER_SUCCESS" });
        toast.success(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING_OFF" });
        console.log(err.response);
      });
  };
};

// Get accounts by email
export const getAccountsByEmail = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(`${url}/accounts/getAllAccountsDTOByEmail`, data)
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: "SET_TEAM_MEMBER_ACCOUNTS",
            payload: response.data,
          });
          console.log(response.data);
        } else {
          toast.error("No user found!");
          dispatch({ type: "SET_LOADING_OFF" });
        }
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING_OFF" });
        toast.error("An error occured! Please try again");
        console.log(err.response);
      });
  };
};

// Get team members
export const getTeamMembers = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING_ON" });
    axios
      .post(
        `${url}/accounts/getAccountHPriviledgesDTOByProfileIDandProfileType`,
        data
      )
      .then((response) => {
        dispatch({ type: "SET_LOADING_OFF" });
        dispatch({ type: "SET_TEAM_MEMBERS", payload: response.data });
        console.log(response.data);
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING_OFF" });
        console.log(err.response);
      });
  };
};
