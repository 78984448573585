import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Accounts from "../../components/Reusables/Accounts";
import ReactLoading from "react-loading";
// eslint-disable-next-line
import { url } from "../../lib/links";
import { supportedCountries } from "../../lib/links";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  signUpUser,
  onboardUser,
  createRoles,
} from "../../app/userReducer/actions";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  //eslint-disable-next-line
  const [showAccountForm, setShowAccountForm] = useState(true);
  //eslint-disable-next-line
  const [showAccount, setShowAccount] = useState(false);
  const { loading, accountSuccess } = useSelector((state) => state.utilities);
  const [personal, setPersonal] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    language: "",
    currency: "",
    sourcesOfFunds: "",
    street: "",
    building: "",
    region: "",
    town: "",
    country: "",
    postCode: "",
    email: "",
    pin: "",
    confirmPin: "",
    phoneNumber: "",
  });
  const [gender, setGender] = useState("Male");
  const state = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nigeriaCurrency = [
    {
      cdef: "",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "",
      currency: "GBP",
      status: "Active",
    },
    {
      cdef: "default",
      currency: "NGN",
      status: "Active",
    },
  ];

  const europeanCurrencies = [
    {
      cdef: "default",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "",
      currency: "GBP",
      status: "Active",
    },
    {
      cdef: "",
      currency: "NGN",
      status: "Active",
    },
  ];

  const ukCurrencies = [
    {
      cdef: "",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "default",
      currency: "GBP",
      status: "Active",
    },
    {
      cdef: "",
      currency: "NGN",
      status: "Active",
    },
  ];

  const usCurrencies = [
    {
      cdef: "",
      currency: "EUR",
      status: "Active",
    },
    {
      cdef: "default",
      currency: "USD",
      status: "Active",
    },
    {
      cdef: "",
      currency: "GBP",
      status: "Active",
    },
  ];

  const nigeriaDefault = nigeriaCurrency.filter(
    (currency) => currency.cdef === "default"
  );
  const europeanDefault = europeanCurrencies.filter(
    (currency) => currency.cdef === "default"
  );
  const usDefault = usCurrencies.filter(
    (currency) => currency.cdef === "default"
  );
  const ukDefault = ukCurrencies.filter(
    (currency) => currency.cdef === "default"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !personal.firstName ||
      !personal.lastName ||
      !personal.dateOfBirth ||
      !personal.street ||
      !personal.region ||
      !personal.town ||
      !personal.country ||
      !personal.postCode ||
      !personal.pin ||
      !personal.phoneNumber
    ) {
      toast.error("Please fill all required fields!");
      return;
    }
    if (personal.pin.length !== 4) {
      toast.error("Pin must be equal to 4 digits");
      return;
    }
    if (personal.pin !== personal.confirmPin) {
      toast.error("Pin is not the same as Confirm Pin");
      return;
    }

    dispatch(
      signUpUser({
        bvn: "",
        customerAddress: [
          {
            city: personal.town,
            country: personal.country,
            postalCode: personal.postCode,
            state: personal.region,
            status: "",
            street: personal.street,
          },
        ],
        dateOfBirth: personal.dateOfBirth,
        email: sessionStorage.getItem("email"),
        firstName: personal.firstName,
        gender: gender,
        identity: [
          {
            countryOfIssue: "",
            idNumber: "",
            idType: "",
            status: "",
          },
        ],
        lastName: personal.lastName,
        localGovernment: "",
        nationality: "",
        otherNames: "",
        phone: 0,
        salutation: "",
        status: "Active",
        personalcurrencies:
          personal.country === "Nigeria"
            ? nigeriaCurrency
            : personal.country === "United States"
            ? usCurrencies
            : personal.country === "United Kingdom"
            ? ukCurrencies
            : europeanCurrencies,
      })
    );
  };

  useEffect(() => {
    if (state.userID) {
      dispatch(
        onboardUser({
          accountName: `${personal.firstName} ${personal.lastName}`,
          accountOpeningDate: Date.now(),
          accountType: "Personal",
          availableBalance: "0",
          clearedBalance: "0",
          currency:
            personal.country === "Nigeria"
              ? nigeriaDefault[0].currency
              : personal.country === "United States of America"
              ? usDefault[0].currency
              : personal.country === "United Kingdom"
              ? ukDefault[0].currency
              : europeanDefault[0].currency,
          customerId: state.userID,
          decimal: "0",
          email: sessionStorage.getItem("email"),
          fullName: `${personal.firstName} ${personal.lastName}`,
          holdBalance: "0",
          lastTransactionDate: "2022-01-20T16:25:43.544Z",
          maximumBalance: "10000",
          minimumBalance: "100000",
          phoneNumber: personal.phoneNumber,
          pin: personal.pin,
          status: "Active",
          unclearedBalance: "0",
        })
      );

      dispatch(
        createRoles({
          profileid: state.userID,
          profiletype: "Personal",
          status: "Active",
          userid: sessionStorage.getItem("email"),
          userrole: "OWNER",
        })
      );
    }

    // eslint-disable-next-line
  }, [state.userID]);

  useEffect(() => {
    if (accountSuccess) {
      navigate("/success");
    }
    // eslint-disable-next-line
  }, [accountSuccess]);

  return (
    <>
      <Navbar />
      {showAccountForm && (
        <div>
          <div className="profile__details">
            <h3>Personal Profile Details</h3>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="first name"
                    placeholder="First Name"
                    value={personal.firstName}
                    onChange={(e) =>
                      setPersonal({ ...personal, firstName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="last name"
                    placeholder="Last Name"
                    value={personal.lastName}
                    onChange={(e) =>
                      setPersonal({ ...personal, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form__group">
              <label htmlFor="date">Date of Birth</label>
              <input
                type="date"
                name="date of birth"
                value={personal.dateOfBirth}
                onChange={(e) =>
                  setPersonal({ ...personal, dateOfBirth: e.target.value })
                }
              />
            </div>

            <div className="form__group">
              <label htmlFor="gender">Gender</label>
              <select
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            {/* <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="form__group">
                <input
                  type="text"
                  name="language"
                  placeholder="Language"
                  value={personal.language}
                  onChange={(e) =>
                    setPersonal({ ...personal, language: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="form__group">
                <input
                  type="text"
                  name="currency"
                  placeholder="Currency"
                  value={personal.currency}
                  onChange={(e) =>
                    setPersonal({ ...personal, currency: e.target.value })
                  }
                />
              </div>
            </div>
          </div> */}
            {/* <div className="form__group">
            <input
              type="text"
              name="sources of funds"
              placeholder="Sources of funds/wealth"
              value={personal.sourcesOfFunds}
              onChange={(e) =>
                setPersonal({ ...personal, sourcesOfFunds: e.target.value })
              }
            />
          </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="street"
                    placeholder="First line of address"
                    value={personal.street}
                    onChange={(e) =>
                      setPersonal({ ...personal, street: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="building"
                    placeholder="Second line of address (if applicable)"
                    value={personal.building}
                    onChange={(e) =>
                      setPersonal({ ...personal, building: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="region"
                    placeholder="Region/State/County/Province"
                    value={personal.region}
                    onChange={(e) =>
                      setPersonal({ ...personal, region: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form__group">
                  <input
                    type="text"
                    name="town/city"
                    placeholder="Town/City"
                    value={personal.town}
                    onChange={(e) =>
                      setPersonal({ ...personal, town: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form__group">
              <input
                type="text"
                name="Postal Code"
                placeholder="Post Code"
                value={personal.postCode}
                onChange={(e) =>
                  setPersonal({ ...personal, postCode: e.target.value })
                }
              />
            </div>
            <div className="form__group">
              <select
                name="country"
                value={personal.country}
                onChange={(e) =>
                  setPersonal({
                    ...personal,
                    country: e.target.value,
                  })
                }
              >
                <option hidden>Select Country</option>
                {supportedCountries.map((country, index) => (
                  <option value={country.name} key={index}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Phone Number */}
            <div className="form__group">
              <input
                type="text"
                name="phone number"
                placeholder="Phone Number"
                className="form__control"
                value={personal.phoneNumber}
                onChange={(e) =>
                  setPersonal({ ...personal, phoneNumber: e.target.value })
                }
              />
            </div>
            {/* <h5 className="mt-2 mb-4">Currency Account Settings</h5> */}
            <div className="form__group">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <input
                    type="password"
                    name="account pin"
                    value={personal.pin}
                    onChange={(e) =>
                      setPersonal({ ...personal, pin: e.target.value })
                    }
                    placeholder="Set Account Pin"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    type="password"
                    name="confirm pin"
                    value={personal.confirmPin}
                    onChange={(e) =>
                      setPersonal({ ...personal, confirmPin: e.target.value })
                    }
                    placeholder="Confirm Account Pin"
                  />
                </div>
              </div>
            </div>

            <div className="form__group">
              <button onClick={handleSubmit}>
                {!loading ? (
                  "Submit"
                ) : (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    width={30}
                    height={30}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {showAccount && (
        <Accounts
          type="Personal"
          title={"Set up your Personal Account"}
          id={state.userID}
          firstName={personal.firstName}
          lastName={personal.lastName}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default Customers;
