import React, { useState } from "react";
import axios from "axios";
import { url } from "../../lib/links";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const token = sessionStorage.getItem("token");

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const TransactionSuccessful = () => {
  return (
    <div className="transaction__successful shadow mt-5 mb-4">
      <i className="fas fa-check-circle"></i>
      <h3>Transaction Successful</h3>
    </div>
  );
};

const TransferToBank = () => {
  const [accountNo, setAccountNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [pin, setPin] = useState(null);
  const [confirmPin, setConfirmPin] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState({});
  const [showSuccessful, setShowSuccessful] = useState(false);

  // Get sender's account
  const { accounts } = useSelector((state) => state.user);
  console.log(accounts);

  const handleVerifiyAccountNumber = async () => {
    if (!amount || !description) {
      toast.error("Incomplete transaction details");
      return;
    }
    if (amount > accounts[0].availableBalance) {
      toast.error("Insufficient Balance");
      return;
    }
    if (accounts[0].availableBalance - amount < 0) {
      toast.error("Insufficient Balance");
      return;
    }
    setLoading(true);
    await axios
      .post(`${url}/accounts/getAllAccountDetailsDTOByAccountNo`, {
        id: accountNo,
      })
      .then((response) => {
        // setLoading(false);
        console.log(response.data);
        setRecipient(response.data);
        axios
          .post(`${url}/accounts/createSingleTransferToBankDTO`, {
            auth: {
              secure: ["otp"],
              type: "",
            },
            requestRef: "",
            requestType: "",
            statusWebHook: "",
            transaction: {
              amount: amount,
              channel: "web",
              customer: {
                accountNumber: [accounts[0].accountNumber],
                email: accounts[0].email,
                firstname: accounts[0].accountName,
                mobileNumber: "",
                sourceAccount: accounts[0].accountNumber,
                sourceAccountName: accounts[0].accountName,
                sourceCurrency: accounts[0].currency,
                surname: "",
              },
              destinationAccount: response.data.accountNumber,
              destinationAccountName: response.data.accountName,
              destinationBankCode: "",
              destinationBankName: "Rohipay",
              destinationCurrency: response.data.currency,
              destinationNarration: description,
              latitude: 0,
              longitude: 0,
              transactionDescription: description,
              transactionRef: "",
              transactionRefParent: "",
            },
            type: "",
          })
          .then((response) => {
            setLoading(false);
            console.log(response.data);
            setPaymentResponse(response.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
            toast.error(err.response);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  const confirmPinAndPay = async () => {
    if (!pin || !confirmPin) {
      toast.error("Incomplete transaction details");
      return;
    }
    if (pin !== confirmPin) {
      toast.error("Pin and confirm pin must be the same");
      return;
    }
    setLoading(true);
    axios
      .post(`${url}/accounts/verifyAccountPin`, {
        accountNumber: accounts[0].accountNumber,
        pin,
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        if (response.data) {
          console.log(paymentResponse);
          axios
            .post(`${url}/accounts/TransactionCompletionOBADTO`, {
              authtype: [
                {
                  autId: 0,
                  type: "OTP",
                  value: paymentResponse.data.authDetails.label,
                },
              ],
              requestRef: paymentResponse.data.transactionRef,
              status: "Done",
              transactionRef: paymentResponse.data.transactionRef,
            })
            .then((response) => {
              setLoading(false);
              console.log(response.data);
              toast.success(response.data.message);
              setShowSuccessful(true);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err.response);
            });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        toast.error(err.response);
      });
  };
  return (
    <>
      {!showSuccessful ? (
        <div className="transfer_toBank shadow m-3 p-3">
          <h3>Transfer to Bank</h3>
          <div className="form__group">
            <input
              type="text"
              name="account number"
              placeholder="Enter Recipient's Account Number"
              className="form__control"
              value={accountNo}
              onChange={(e) => setAccountNo(e.target.value)}
              disabled={recipient}
            />
          </div>
          <div className="form__group">
            <input
              type="text"
              name="amount"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              disabled={recipient}
            />
          </div>
          <div className="form__group">
            <input
              type="text"
              name="Description"
              placeholder="Transaction Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={recipient}
            />
          </div>
          {recipient && (
            <>
              <div className="row form__group">
                <div className="col-sm-12 col-md-6">
                  <input type="text" disabled value={recipient.accountName} />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input type="text" disabled value={recipient.accountNumber} />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    type="password"
                    name="account pin"
                    placeholder="Account Pin"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    type="password"
                    name="confirm account pin"
                    placeholder="Confirm Account Pin"
                    value={confirmPin}
                    onChange={(e) => setConfirmPin(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="form__group">
            {recipient ? (
              <button onClick={confirmPinAndPay}>
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color={"#fff"}
                    width={30}
                    height={30}
                  />
                ) : (
                  "Complete Transaction"
                )}
              </button>
            ) : (
              <button
                className={accountNo ? "" : "disabled__button"}
                onClick={handleVerifiyAccountNumber}
                disabled={!accountNo || !amount || !description}
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color={"#fff"}
                    width={30}
                    height={30}
                  />
                ) : (
                  "Confirm and Pay"
                )}
              </button>
            )}
          </div>
        </div>
      ) : (
        <TransactionSuccessful />
      )}
      <ToastContainer />
    </>
  );
};

export default TransferToBank;
