import React from "react";
import Footer from "../components/Footer";
import MobileApp from "../components/MobileApp";
import Home from "../components/Reusables/Home";

const Merchant = () => {
  return (
    <div>
      <Home
        title="MERCHANT ACCOUNT"
        heading="Increase revenue with our merchant payment services, receive money from customers"
        description="Small & Big businesses offering merchant services like sole traders, registered businesses, limited companies & Non-profit organisation."
        image="https://media.istockphoto.com/photos/modern-african-waiter-handing-over-payment-terminal-in-caf-picture-id1311527965?b=1&k=20&m=1311527965&s=170667a&w=0&h=NiLVb-PYfBlVPzsLYaDTkA8AMmqD706d7FaIe2xjbsQ="
      />
      <MobileApp />
      <Footer />
    </div>
  );
};

export default Merchant;
