import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountPrivileges,
  addTeamMember,
} from "../../../app/userReducer/actions";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import {useNavigate} from 'react-router-dom'

const AddMerchantTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { teamPrivileges, profile } = useSelector(
    (state) => state.user
  );
  const { loading, teamMemberSuccess } = useSelector((state) => state.utilities);
  const [activeDiv, setActiveDiv] = useState(null);
  const [userID, setUserID] = useState("");
  console.log(profile)

  useEffect(() => {
    dispatch(getAccountPrivileges());
    // eslint-disable-next-line
  }, []);

  const handleActiveDiv = (div) => {
    setActiveDiv(div);
  };

  const setActiveDivClass = (div) => {
    if (div === activeDiv) {
      return `active__team role__card`;
    } else {
      return `role__card`;
    }
  };

  const handleAddTeamMember = () => {
    if (!userID || !activeDiv) {
      toast.error("Incomplete team member details");
      return;
    }

    console.log(userID, activeDiv);
    dispatch(addTeamMember({
      profileid: profile.merchantId,
      profiletype: "Merchant",
      status: "Active",
      userid: userID,
      userrole: activeDiv,
    }))
  };

  useEffect(() => {
    if (teamMemberSuccess) {
      navigate("/dashboard/teams")
      window.location.reload()
    }
    // eslint-disable-next-line
  }, [teamMemberSuccess])
  return (
    <div className="add__merchantTeam">
      <h3>Add a team member</h3>
      <div className="team__formArea shadow p-3 bg-body">
        <div className="form__group">
          <input
            type="User ID"
            name="Merchant User ID"
            placeholder="User ID"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
        </div>
        <h5 className="mb-4 mt-3">Choose a role</h5>
        <div className="roles__section p-1">
          {teamPrivileges.length > 0 &&
            teamPrivileges.map((privilege, index) => (
              <div
                className={setActiveDivClass(privilege.apriviledge)}
                onClick={() => handleActiveDiv(privilege.apriviledge)}
                key={index}
              >
                <div className="role__details">
                  {privilege.apriviledge === "Admin" && (
                    <span className="privilege__icon">
                      <i className="fas fa-user-shield"></i>
                    </span>
                  )}{" "}
                  {privilege.apriviledge === "OWNER" && (
                    <span className="privilege__icon">
                      <i className="fas fa-user-cog"></i>
                    </span>
                  )}
                  {privilege.apriviledge === "Employee" && (
                    <span className="privilege__icon">
                      <i className="fas fa-pager"></i>
                    </span>
                  )}
                  {privilege.apriviledge === "Viewer" && (
                    <span className="privilege__icon">
                      <i className="fas fa-eye"></i>
                    </span>
                  )}
                  {privilege.apriviledge === "Preparer" && (
                    <span className="privilege__icon">
                      <i className="fas fa-pen"></i>
                    </span>
                  )}
                  {privilege.apriviledge === "Payer" && (
                    <span className="privilege__icon">
                      <i className="fas fa-money-bill"></i>
                    </span>
                  )}
                  <div className="role__description">
                    <h5>{privilege.apriviledge}</h5>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Aliquid perferendis ab fugit aspernatur.
                    </p>
                  </div>
                </div>
                <span className="role__chosen">
                  <i className="fas fa-circle-notch"></i>
                </span>
              </div>
            ))}
        </div>
        <div className="form__group">
          <button onClick={handleAddTeamMember}>
            {loading ? (
              <ReactLoading color="#fff" type="spin" width={30} height={30} />
            ) : (
              "Add Member"
            )}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddMerchantTeam;
