import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Redirect from "../../lib/Redirect";
import { url } from "../../lib/links";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { loginUser } from "../../app/userReducer/actions";
import ReactLoading from "react-loading";

const Login = () => {
  const state = useSelector((state) => state.user);
  const [user, setUser] = useState({ email: "", password: null });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errorMsg, setErrormsg] = useState(null);

  const signInUser = async (e) => {
    e.preventDefault();
    if (!user.email || !user.password) {
      toast.error("Please fill the fields!");
      return;
    }
    if (user.password.length !== 4) {
      toast.error("Password must be equal to four");
      return;
    }
    setLoading(true);
    sessionStorage.setItem("userId", user.email);
    await axios
      .post(
        `${url}/login`,
        { username: user.email, password: user.password },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        window.location.reload();
        dispatch(loginUser(response.headers.authorization));
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("An error occured! Please try again");
        setLoading(false);
      });
  };

  function isNumberKey(evt) {
    const re = /^[0-9\b]+$/;

    if (evt.target.value === "" || re.test(evt.target.value)) {
      console.log(true);
      setUser({ ...user, password: evt.target.value });
      setErrormsg("");
    } else {
      setErrormsg("Only numbers are allowed!");
    }
  }
  if (state.isLoggedIn) return <Redirect to="/dashboard" />;
  return (
    <div>
      <Navbar />
      <div className="login__area bg-light">
        <form>
          <h3>Welcome Back, Login</h3>
          <div className="form__group">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className="form__group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="bg-light"
              onKeyPress={isNumberKey}
              value={user.password}
              onChange={isNumberKey}
            />
          </div>
          {errorMsg && (
            <div style={{ color: "red", fontSize: "14px" }}>{errorMsg}</div>
          )}
          <div className="forgot__password">
            <Link to="/forgot-password">Forgot your password?</Link>
          </div>
          <div className="form__group">
            <button onClick={signInUser}>
              {!loading ? (
                "Login"
              ) : (
                <ReactLoading
                  color="#fff"
                  type={"spin"}
                  width={30}
                  height={30}
                />
              )}
            </button>
          </div>
          <div className="no__account">
            <p>
              Don't have an account? <Link to="/options">Sign Up</Link>
            </p>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Login;
