import { combineReducers } from "redux";

import { userReducer } from "./userReducer/reducers";
import { accountReducer } from "./accountReducer/reducers";
import { PersonalReducer } from './PersonalReducer/reducers'
import { merchantReducer } from './MerchantReducer/reducers'
import { utilitiesReducer } from './utilitiesReducer/reducers'

export const rootReducer = combineReducers({
  user: userReducer,
  accountType: accountReducer,
  personal: PersonalReducer,
  merchant: merchantReducer,
  utilities: utilitiesReducer
});
