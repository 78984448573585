import React from "react";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div>
      <Navbar />
      <div className="forgot__password bg-light">
        <h3>Forgot Password</h3>
        <p>Enter your registered mobile number or email address</p>
        <div className="form__group">
          <input
            type="text"
            name="input"
            placeholder="Mobile No. or Email Address"
            className="bg-light"
          />
        </div>
        <div className="form__group">
            <button>Reset</button>
        </div>
        <div className="to__login">
          <Link to="/login">Back to Login</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
