import React from "react";

const ContactSocial = ({socialMedia, setSocialMedia}) => {
  return (
    <div>
      <div className="form__group">
        <input
          type="text"
          name="website"
          placeholder="Business Website"
          className="form__control"
          value={socialMedia.website}
          onChange={e => setSocialMedia({...socialMedia, website: e.target.value})}
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="social1"
          placeholder="Twitter Handle"
          className="form__control"
          value={socialMedia.twitter}
          onChange={e => setSocialMedia({...socialMedia, twitter: e.target.value})}
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="social2"
          placeholder="Linkedin Username"
          className="form__control"
          value={socialMedia.linkedin}
          onChange={e => setSocialMedia({...socialMedia, linkedin: e.target.value})}
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="social3"
          placeholder="Instagram Handle"
          className="form__control"
          value={socialMedia.instagram}
          onChange={e => setSocialMedia({...socialMedia, instragram: e.target.value})}
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="social4"
          placeholder="Facebook Handle"
          className="form__control"
          value={socialMedia.facebook}
          onChange={e => setSocialMedia({...socialMedia, facebook: e.target.value})}
        />
      </div>
    </div>
  );
};

export default ContactSocial;
