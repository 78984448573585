import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { onboardUser, createRoles } from "../../app/userReducer/actions";
import { useDispatch, useSelector } from "react-redux";

const Accounts = ({ type, title, id, firstName, lastName }) => {
  const [account, setAccount] = useState({
    accountName: "",
    accountOpeningDate: Date.now(),
    accountType: type,
    availableBalance: "",
    clearedBalance: "",
    currency: "",
    customerId: "",
    decimal: "",
    email: "",
    holdBalance: "",
    lastTransactionDate: "",
    maximumBalance: "",
    minimumBalance: "",
    phoneNumber: "",
    pin: "",
    status: "",
    unclearedBalance: "",
    confirmPin: "",
  });
  const { loading, accountSuccess } = useSelector((state) => state.utilities);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCreateAccount = async () => {
    if (!account.accountType) {
      toast.error("Please select account currency");
      return;
    }
    if (!account.pin || !account.confirmPin) {
      toast.error("Please set account pin");
      return;
    }
    if (account.pin !== account.confirmPin) {
      toast.error("Make sure your confirm pin is the same as pin");
      return;
    }
    if (account.pin.length !== 4) {
      toast.error("Account pin must be length of 4");
      return;
    }
    dispatch(
      onboardUser({
        accountName: `${firstName} ${lastName}`,
        accountOpeningDate: Date.now(),
        accountType: account.accountType,
        availableBalance: "0",
        clearedBalance: "0",
        currency: account.currency,
        customerId: id,
        decimal: "0",
        email: sessionStorage.getItem("email"),
        fullName: `${firstName} ${lastName}`,
        holdBalance: "0",
        lastTransactionDate: "2022-01-20T16:25:43.544Z",
        maximumBalance: "10000",
        minimumBalance: "100000",
        phoneNumber: account.phoneNumber,
        pin: account.pin,
        status: "Active",
        unclearedBalance: "0",
      })
    );

    dispatch(
      createRoles({
        profileid: id,
        profiletype: "Merchant",
        status: "Active",
        userid: sessionStorage.getItem("email"),
        userrole: "OWNER",
      })
    );
  };

  useEffect(() => {
    if (accountSuccess) {
      navigate("/success");
      localStorage.setItem("accountItem", "");
      localStorage.setItem("email", "");
    }
    // eslint-disable-next-line
  }, [accountSuccess]);
  return (
    <div>
      <div className="accounts__section bg-light">
        <h3>{title}</h3>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <select
              name="currency"
              value={account.currency}
              onChange={(e) =>
                setAccount({ ...account, currency: e.target.value })
              }
            >
              <option hidden>Select Currency</option>
              <option value="USD">Dollars</option>
              <option value="EUR">Euro</option>
              <option value="GBP">Pound</option>
            </select>
          </div>
          <div className="col-sm-12 col-md-6">
            <input
              type="text"
              name="phone Number"
              placeholder="Phone Number"
              value={account.phoneNumber}
              onChange={(e) =>
                setAccount({ ...account, phoneNumber: e.target.value })
              }
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <input
              type="password"
              name="pin"
              value={account.pin}
              onChange={(e) => setAccount({ ...account, pin: e.target.value })}
              placeholder="Set Account Pin"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <input
              type="password"
              name="confirm pin"
              placeholder="Confirm Account Pin"
              value={account.confirmPin}
              onChange={(e) =>
                setAccount({ ...account, confirmPin: e.target.value })
              }
            />
          </div>
        </div>
        <button onClick={handleCreateAccount}>
          {!loading ? (
            "Create Account"
          ) : (
            <ReactLoading type="spin" color="#fff" width={30} height={30} />
          )}
        </button>
      </div>
    </div>
  );
};

export default Accounts;
