import React from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

import "../styles/Home.css";

const Onboarded = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="onboarded__section">
        <i className="fas fa-check-circle"></i>
        <p>You've been onboarded successfully</p>
        <button
          onClick={() => {
            navigate("/login");
          }}
        >
          Continue to Login
        </button>
      </div>
    </div>
  );
};

export default Onboarded;
