// export const url = "https://limitless-citadel-60501.herokuapp.com";
// export const url = "https://rohipay-backend.herokuapp.com";
export const url = "http://35.181.60.143:8443";
// export const url = "http://localhost:8443";

export const dates = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23,
  24, 25, 26, 27, 28, 29, 30, 31,
];
export const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const supportedCountries = [
  { name: "Austria", region: "Europe" },
  { name: "Belgium", region: "Europe" },
  { name: "Bulgaria", region: "Europe" },
  { name: "Croatia", region: "Europe" },
  { name: "Republic of Cyprus", region: "Europe" },
  { name: "Czech Republic", region: "Europe" },
  { name: "Denmark", region: "Europe" },
  { name: "Estonia", region: "Europe" },
  { name: "Finland", region: "Europe" },
  { name: "France", region: "Europe" },
  { name: "Germany", region: "Europe" },
  { name: "Greece", region: "Europe" },
  { name: "Hungary", region: "Europe" },
  { name: "Iceland", region: "Europe" },
  { name: "Ireland", region: "Europe" },
  { name: "Italy", region: "Europe" },
  { name: "Latvia", region: "Europe" },
  { name: "Liechtenstein", region: "Europe" },
  { name: "Lithuania", region: "Europe" },
  { name: "Luxemburg", region: "Europe" },
  { name: "Malta", region: "Europe" },
  { name: "Norway", region: "Europe" },
  { name: "Netherlands", region: "Europe" },
  { name: "Nigeria", region: "Africa" },
  { name: "Poland", region: "Europe" },
  { name: "Portugal", region: "Europe" },
  { name: "Romania", region: "Europe" },
  { name: "Slovakia", region: "Europe" },
  { name: "Slovenia", region: "Europe" },
  { name: "Spain", region: "Europe" },
  { name: "Sweden", region: "Europe" },
  { name: "Switzerland", region: "Europe" },
  { name: "United Kingdom", region: "United Kingdom" },
  { name: "United States", region: "United States" },
];
