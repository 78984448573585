import React from "react";
import Footer from "../components/Footer";
import MobileApp from "../components/MobileApp";
import Home from "../components/Reusables/Home";
import "../styles/Home.css";

const Personal = () => {
  return (
    <div>
      <Home
        title="PERSONAL ACCOUNT"
        heading="Safe & secure payment service"
        description="With a Rohipay personal account, you have a wallet. You can receive payments instantly, send money to anyone and transfer funds. Ideal for individual user for personal use."
        image="https://media.istockphoto.com/photos/woman-doing-finances-at-home-on-smart-phone-picture-id1172587375?b=1&k=20&m=1172587375&s=170667a&w=0&h=3s4y7-8Hq85gcwqS7DT2f2yyrr0CkSqdvdn5UzySl-s="
      />
      <MobileApp />
      <Footer />
    </div>
  );
};

export default Personal;
