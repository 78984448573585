import React, { useState } from "react";

// React Toastify
// eslint-disable-next-line
import { toast, ToastContainer } from "react-toastify";
import Personal from "../Personal";
import Token from "../Token";

// const token = localStorage.getItem("accountItem");
// axios.interceptors.request.use(
//   (config) => {
//     config.headers.authorization = token;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

const Index = ({ title, secondTitle }) => {
  const [firstPosition, setFirstPosition] = useState(0);
  // eslint-disable-next-line
  const [showAccount, setShowAccount] = useState(false);
  const [showFirstStep, setShowFirstStep] = useState(true);
  // eslint-disable-next-line
  const [showLastStep, setShowLastStep] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
  });
  
  const [agree, setAgree] = useState(false);
  

  // console.log(state);

  const firstSteps = [
    {
      name: "Credentials",
      component: (
        <Personal
          user={user}
          setUser={setUser}
          position={firstPosition}
          setPosition={setFirstPosition}
          title="Merchant"
        />
      ),
    },
    {
      name: "Token",
      component: (
        <Token
          user={user}
          setUser={setUser}
          agree={agree}
          setAgree={setAgree}
          setShowAccount={setShowAccount}
          setShowFirstStep={setShowFirstStep}
        />
      ),
    },
  ];

 
  
  return (
    <>
      <div className="merchant__section bg-light">
        {showFirstStep && firstSteps[firstPosition].component}
        
      </div>
      <ToastContainer />
    </>
  );
};

export default Index;
