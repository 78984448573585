import React from "react";

const TransactionsTable = ({ transaction, accounts }) => {
  console.log(transaction, accounts);
  return (
    <tbody>
      <tr>
        <td>
          {transaction.sourceAccount === accounts.accountNumber && (
            <i className="fas fa-angle-up arrow__up"></i>
          )}
          {transaction.destinationAccount === accounts.accountNumber && (
            <i className="fas fa-angle-down arrow__down"></i>
          )}
        </td>
        <td>{transaction.sourceAccount}</td>
        <td>{transaction.sourceAccountName}</td>
        <td>
          {transaction.currency === "EUR" && "€"}
          {transaction.currency === "USD" && "$"}
          {transaction.currency === "GBP" && "£"}
          {transaction.currency === "NGN" && "₦"}
          {transaction.amount.toLocaleString()}
        </td>
        <td>
          {transaction.transactionDate[2]}/{transaction.transactionDate[1]}/
          {transaction.transactionDate[0]}
        </td>
      </tr>
    </tbody>
  );
};

export default TransactionsTable;
