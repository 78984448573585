import React from "react";
import { Link } from "react-router-dom";

import Logo from "../images/Logo15.png";

const Footer = () => {
  return (
    <div className="footer__section">
      <div className="company">
        <img src={Logo} alt="Logo avatar" width={40} />
        <p>
          Rohipay is ideal for individual users, all types of business including
          small business, SME, Big Business and Non-Profit Organisation.
        </p>
      </div>
      <div className="footer__links">
        <h5>Quick Links</h5>
        <div className="links">
          <p>
            <Link to="/">About</Link>
          </p>
          <p>
            <Link to="/">Terms of Use</Link>
          </p>
          <p>
            <Link to="/">Privacy Policy</Link>
          </p>
          <p>
            <Link to="/">How it Works</Link>
          </p>
          <p>
            <Link to="/">Contact Us</Link>
          </p>
        </div>
      </div>
      <div className="footer__links">
        <h5>Support</h5>
        <div className="links">
          <p>
            <Link to="/">Support Center</Link>
          </p>
          <p>
            <Link to="/">Help Center</Link>
          </p>
          <p>
            <Link to="/">Quick Chat</Link>
          </p>
        </div>
      </div>
      <div className="footer__links">
        <h5>Connect with Rohipay</h5>
        <div className="links">
          <p>
            <Link to="/">Facebook</Link>
          </p>
          <p>
            <Link to="/">Twitter</Link>
          </p>
          <p>
            <Link to="/">LinkedIn</Link>
          </p>
          <p>
            <Link to="/">Instagram</Link>
          </p>
          {/* <Link to="/">Contact Us</Link> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
