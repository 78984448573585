import React, { useState, useEffect } from "react";
import BusinessInfo from "../Business/BusinessInfo";
import PersonalBusiness from "../Business/PersonalBusiness";
import { useDispatch, useSelector } from "react-redux";
import {
  onboardMerchant,
  createMerchant,
} from "../../../app/userReducer/actions";
import ContactSocial from "../ContactSocial";
import { toast, ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";
import Accounts from "../Accounts";
import Navbar from "../../Navbar";

const MerchantStep = () => {
  const [position, setPosition] = useState(0);
  const [showAccount, setShowAccount] = useState(true);
  const [showLastStep, setShowLastStep] = useState(false);
  const [socialMedia, setSocialMedia] = useState({
    twitter: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    website: "",
  });
  const [merchant, setMerchant] = useState({
    name: "",
    type: "",
    businessCountry: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    street: "",
    building: "",
    region: "",
    town: "",
    postCode: "",
    industry: "",
    description: "",
  });
  // eslint-disable-next-line
  const [user, setUser] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
  });
  const [location, setLocation] = useState({ latitude: "", longitude: "" });

  const state = useSelector((state) => state.user);
  // console.log(state.userID)
  const { loading, success, merchantSuccess } = useSelector(
    (state) => state.utilities
  );
  const dispatch = useDispatch();
  const steps = [
    {
      name: "Merchant Information",
      component: (
        <BusinessInfo
          title={"Merchant Account Information"}
          business={merchant}
          setBusiness={setMerchant}
        />
      ),
    },
    {
      name: "Merchant Handler Information",
      component: (
        <PersonalBusiness
          secondTitle={"Account Handler Information"}
          business={merchant}
          setBusiness={setMerchant}
        />
      ),
    },
    {
      name: "Contact and Social Media",
      component: (
        <ContactSocial
          socialMedia={socialMedia}
          setSocialMedia={setSocialMedia}
        />
      ),
    },
  ];
  const handlePosition = (e) => {
    e.preventDefault();
    if (position === 1) {
      return;
    }
    if (
      !merchant.name ||
      !merchant.type ||
      !merchant.businessCountry ||
      !merchant.industry ||
      !merchant.description
    ) {
      toast.error("Please fill all fields");
      return;
    }
    setPosition(position + 1);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (
      !merchant.firstName ||
      !merchant.lastName ||
      !merchant.dateOfBirth ||
      !merchant.street ||
      !merchant.building ||
      !merchant.town ||
      !merchant.postCode
    ) {
      toast.error("Please fill all required fields!");
      return;
    }
    dispatch(
      onboardMerchant({
        agId: 0,
        businesstype: merchant.type,
        companyname: merchant.name,
        contactInfo: [
          {
            ccId: 0,
            description: "",
            type: "",
            value: "",
          },
        ],
        description: merchant.description,
        email: sessionStorage.getItem("email"),
        firstname: merchant.firstName,
        industry: merchant.industry,
        lastname: merchant.lastName,
        location: {
          city: merchant.town,
          country: merchant.businessCountry,
          geoLocation: {
            glId: 0,
            latitude: location.latitude,
            longitude: location.longitude,
            radius: 0,
          },
          localId: 0,
          state: merchant.region,
          street: merchant.street,
        },
        logo: "",
        merchantId: "",
        middlename: "",
        mobile: "",
        socialMedia: [
          {
            ccId: 0,
            description: "",
            type: "",
            value: "",
          },
        ],
        status: "Active",
        website: "",
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !socialMedia.twitter &&
      !socialMedia.facebook &&
      !socialMedia.website &&
      !socialMedia.instagram &&
      !socialMedia.linkedin
    ) {
      toast.error("Please provide atleast one social media link");
      return;
    }
    // Post request
    dispatch(
      createMerchant({
        agId: 0,
        businesstype: merchant.type,
        companyname: merchant.name,
        contactInfo: [
          {
            ccId: 0,
            description: "",
            type: "",
            value: "",
          },
        ],
        description: merchant.description,
        email: sessionStorage.getItem("email"),
        firstname: merchant.firstName,
        industry: merchant.industry,
        lastname: merchant.lastName,
        location: {
          city: merchant.town,
          country: merchant.businessCountry,
          geoLocation: {
            glId: 0,
            latitude: location.latitude,
            longitude: location.longitude,
            radius: 0,
          },
          localId: 0,
          state: merchant.region,
          street: merchant.street,
        },
        logo: "",
        merchantId: "",
        middlename: "",
        mobile: "",
        socialMedia: [
          {
            ccId: 1,
            description: "Twitter Account",
            type: "Twitter",
            value: socialMedia.twitter,
          },
          {
            ccId: 2,
            description: "Instagram Account",
            type: "Instagram",
            value: socialMedia.instagram,
          },
          {
            ccId: 3,
            description: "Linkedin Account",
            type: "Linkedin",
            value: socialMedia.linkedin,
          },
          {
            ccId: 4,
            description: "Facebook Account",
            type: "Facebook",
            value: socialMedia.facebook,
          },
        ],
        status: "Active",
        website: socialMedia.website,
      })
    );
  };
  // useEffect
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(displayLocationInfo);
    }
    //eslint-disable-next-line
  }, []);
  function displayLocationInfo(position) {
    const lng = position.coords.longitude;
    const lat = position.coords.latitude;

    console.log(`longitude: ${lng} | latitude: ${lat}`);
    setLocation({ ...location, latitude: lat, longitude: lng });
  }

  // Position useEffect
  useEffect(() => {
    if (success && position === 1) {
      setPosition(position + 1);
    }
  }, [success, position]);
  useEffect(() => {
    if (merchantSuccess) {
      toast.success("Profile created successfully 😄");
      setShowAccount(false);
      setShowLastStep(true);
    }
  }, [merchantSuccess]);
  return (
    <div>
      <Navbar />
      {showAccount && (
        <div className="merchant__details">
          {steps[position].component}
          {position === 0 && (
            <button onClick={handlePosition}>
              {!loading ? (
                "Next"
              ) : (
                <ReactLoading type="spin" color="#fff" width={50} height={50} />
              )}
            </button>
          )}
          {position === 1 && (
            <button onClick={handleSubmit1}>
              {!loading ? (
                "Confirm"
              ) : (
                <ReactLoading type="spin" color="#fff" width={50} height={50} />
              )}
            </button>
          )}
          {position === 2 && (
            <button onClick={handleSubmit}>
              {!loading ? (
                "Create Profile"
              ) : (
                <ReactLoading type="spin" color="#fff" width={50} height={50} />
              )}
            </button>
          )}
        </div>
      )}
      {showLastStep && (
        <div className="personal__details">
          <Accounts
            title="Set Up Merchant Account"
            type="Merchant"
            id={state.userID}
            firstName={merchant.firstName}
            lastName={merchant.lastName}
          />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default MerchantStep;
