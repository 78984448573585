import React, { useEffect } from "react";
import team from "../../../images/team.svg";
import { Link } from "react-router-dom";
import { getTeamMembers } from "../../../app/userReducer/actions";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Table, Button } from "react-bootstrap";

const MerchantTeams = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.utilities);
  const { teamMembers } = useSelector((state) => state.user);
  console.log(teamMembers);

  useEffect(() => {
    dispatch(
      getTeamMembers({
        profileid: user.profileid,
        profiletype: user.profiletype,
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="merchant__teams">
      {loading ? (
        <ReactLoading type="spin" width={30} height={30} color="#000e5e" />
      ) : (
        <>
          {teamMembers.length > 0 ? (
            <div className="teams__section">
              <h3>Team Members</h3>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Username</th>
                    <th>Role</th>
                    {user.userrole[0].apriviledge === "OWNER" && (
                      <th>Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {teamMembers.map((teamMember, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{teamMember.userid}</td>
                      <td>{teamMember.userrole[0].apriviledge}</td>
                      {user.userrole[0].apriviledge === "OWNER" && (
                        <td>
                          <Button className="delete__button" variant="danger">
                            Delete
                          </Button>
                          <Button
                            className="ms-3 edit__button"
                            variant="outline-info"
                          >
                            Edit Role
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {user.userrole[0].apriviledge === "OWNER" && (
                <Link to="/dashboard/add/team-member">
                  <button>Add Team Members</button>
                </Link>
              )}
            </div>
          ) : (
            user.userrole[0].apriviledge === "OWNER" && <div className="empty__teams">
            <img src={team} alt="Team avatar" width={300} />
            <h3>Manage your business effectively with a team</h3>
            <p>
              Give team partners access to your business account and reduce
              the workload on you.
            </p>
            <Link to="/dashboard/add/team-member">
              <button>Add Team Members</button>
            </Link>
          </div>
          )}
        </>
      )}
    </div>
  );
};

export default MerchantTeams;
