import React, { useState } from "react";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import axios from "axios";
import { url } from "../../lib/links";
const Personal = ({
  user,
  setUser,
  setPosition,
  position,
  title,
  setToken,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrormsg] = useState(null);

  // eslint-disable-next-line
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePosition = () => {
    if (!user.email || !user.password || !user.confirmPassword) {
      toast.error("Incomplete credentials");
      return;
    }
    if (user.password !== user.confirmPassword) {
      toast.error("Password and Confirm Password must be equal");
      return;
    }
    if (user.password.length !== 4) {
      toast.error("");
    }
    setLoading(true);
    axios
      .post(`${url}/users/sendtokenemail?email=${user.email}`)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setLoading(false);
          setPosition(position + 1);
          setToken(response.data);
        }
      })
      .catch((err) => {
        toast.error(err.response);
        setLoading(false);
      });
  };

  function isNumberKey(evt) {
    const re = /^[0-9\b]+$/;

    if (evt.target.value === "" || re.test(evt.target.value)) {
      console.log(true);
      setUser({ ...user, password: evt.target.value });
      setErrormsg("");
    } else {
      setErrormsg("Only numbers are allowed!");
    }
  }
  return (
    <div>
      <div className="form__head">
        <h3>Setup {title} Account</h3>
      </div>
      <div className="form__group">
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
      </div>
      {/* <div className="form__group">
        <input
          type="User Id"
          name="User Id"
          placeholder="Set a Personal ID"
          value={user.username}
          onChange={(e) => setUser({ ...user, username: e.target.value })}
        />
      </div> */}
      <div className="form__group show__password">
        {/* <label htmlFor="show password" onClick={togglePassword}>
          Show Password
        </label> */}
        <input
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Choose a Password"
          value={user.password}
          onChange={isNumberKey}
        />
      </div>
      {errorMsg && (
        <div style={{ color: "red", fontSize: "14px" }}>{errorMsg}</div>
      )}
      <div className="form__group">
        <input
          type="password"
          name="confirm password"
          placeholder="Confirm Password"
          value={user.confirmPassword}
          onChange={(e) =>
            setUser({ ...user, confirmPassword: e.target.value })
          }
        />
      </div>
      <div className="form__group">
        <button onClick={handlePosition}>
          {!loading ? (
            "Sign Up"
          ) : (
            <ReactLoading type="spin" color="#fff" width={30} height={30} />
          )}
        </button>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Personal;
