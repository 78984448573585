import { ToastContainer } from "react-toastify";
const initialState = {
  user: {},
  isLoggedIn: sessionStorage.getItem("token") ? true : false,
  token: sessionStorage.getItem("token") || "",
  accountToken: sessionStorage.getItem("accountItem") || "",
  userID: "",
  sessionToken: "",
  transactions: [],
  userPrivileges: [],
  profile: [],
  accounts: [],
  profilePics: "",
  docsRequired: [],
  teamPrivileges: [],
  teamMemberAccounts: [],
  teamMembers: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNUP_USER":
      sessionStorage.setItem("accountItem", action.payload);
      return {
        ...state,
        accountToken: action.payload,
      };
    case "LOGIN_USER":
      sessionStorage.setItem("token", action.payload);
      return {
        ...state,
        isLoggedIn: true,
      };
    case "SET_USERID":
      return {
        ...state,
        userID: action.payload,
      };
    case "SET_SESSION_TOKEN":
      return {
        ...state,
        sessionToken: action.payload,
      };
    case "SET_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload,
      };
    case "LOGOUT_USER":
      sessionStorage.setItem("token", "");
      sessionStorage.setItem("email", "");
      sessionStorage.setItem("user", "");
      sessionStorage.setItem("userId", "");
      return {
        ...state,
        token: "",
        isLoggedIn: false,
      };
    case "SET_PRIVILEGE":
      return { ...state, userPrivileges: action.payload };
    case "SET_PROFILES":
      return { ...state, profile: action.payload };
    case "SET_ACCOUNTS":
      return { ...state, accounts: action.payload };
    case "SET_PROFILE_PICS":
      return { ...state, profilePics: action.payload };
    case "SET_DOCS_REQUIRED":
      <ToastContainer />;
      return { ...state, docsRequired: action.payload };
    case "SET_USER_PRIVILEGES":
      return { ...state, teamPrivileges: action.payload };
    case "SET_TEAM_MEMBER_ACCOUNTS":
      <ToastContainer />;
      return { ...state, teamMemberAccounts: action.payload };
    case "SET_TEAM_MEMBERS":
      return { ...state, teamMembers: action.payload };
    default:
      return state;
  }
};
