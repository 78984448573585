import React from "react";
import { supportedCountries } from "../../../lib/links";

const BusinessInfo = ({ title, business, setBusiness }) => {
  return (
    <div>
      <h3>{title}</h3>
      <div className="form__group">
        <input
          type="text"
          name="company name"
          placeholder="Business Name"
          value={business.name}
          onChange={(e) => setBusiness({ ...business, name: e.target.value })}
        />
      </div>
      <div className="form__group">
        <select
          name="business type"
          value={business.type}
          onChange={(e) => setBusiness({ ...business, type: e.target.value })}
        >
          <option hidden>Business Type</option>
          <option value="Private Company for Profit">
            Private Company for Profit
          </option>
          <option value="Public Company for Profit">
            Public Company for Profit
          </option>
          <option value="Sole Trader/Self Employed">
            Sole Trader/Self Employed
          </option>
          <option value="Non-Profit Company">Non-Profit Company</option>
        </select>
      </div>
      <div className="form__group">
        <select
          name="country of business"
          value={business.businessCountry}
          onChange={(e) =>
            setBusiness({ ...business, businessCountry: e.target.value })
          }
        >
          <option hidden>Country of Operation</option>
          {supportedCountries.map((country, index) => (
            <option value={country.name} key={index}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form__group">
        <select
          name="industry"
          value={business.industry}
          onChange={(e) =>
            setBusiness({ ...business, industry: e.target.value })
          }
        >
          <option hidden>Industry</option>
          <option value="2">Betting</option>
          <option value="1">Binary</option>
          <option value="3">Casino</option>
          <option value="4">Corporate</option>
          <option value="5">Education</option>
          <option value="6">Finance</option>
          <option value="7">Forex</option>
          <option value="8">Gambling</option>
          <option value="9">Gaming</option>
          <option value="10">Integrator</option>
          <option value="11">Lotteries</option>
          <option value="18">Other</option>
          <option value="12">Poker</option>
          <option value="13">Reseller</option>
          <option value="14">Services</option>
          <option value="15">Shopping</option>
          <option value="16">Travel</option>
          <option value="17">Utilities</option>
        </select>
      </div>
      <div className="form__group">
        <textarea
          name="description"
          value={business.description}
          onChange={(e) =>
            setBusiness({ ...business, description: e.target.value })
          }
          placeholder="Business Description"
          cols="30"
          rows="10"
        ></textarea>
      </div>
    </div>
  );
};

export default BusinessInfo;
