import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const accountTypes = [
  { name: "Dollar Account", code: "USD" },
  { name: "Pounds Account", code: "GBP" },
  { name: "Euro Account", code: "EUR" },
];

const FirstStep = ({ account, setAccount }) => {
  const accounts = useSelector((state) => state.personal.accounts);
  return (
    <div className="account__form">
      <div className="form__group">
        <div className="form__row">
          <select
            name="account__type"
            value={account.type}
            onChange={(e) => setAccount({ ...account, type: e.target.value })}
          >
            <option hidden>Account Type</option>
            {accountTypes.map(
              (accountType) =>
                accounts &&
                accounts.map(
                  (account) =>
                    account.currency !== accountType.code && (
                      <option value={accountType.code}>
                        {accountType.name}
                      </option>
                    )
                )
            )}
          </select>
          <input
            type="text"
            name="phone__number"
            placeholder="Phone Number"
            className="form__control"
            value={account.phoneNumber}
            onChange={(e) =>
              setAccount({ ...account, phoneNumber: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};
const PinStep = ({ account, setAccount }) => {
  return (
    <div>
      <h5>Set Pin</h5>
      <div className="form__group">
        <input
          type="number"
          name="pin"
          placeholder="Set Pin"
          className="form__control"
          value={account.pin}
          onChange={(e) => setAccount({ ...account, pin: e.target.value })}
        />
        <input
          type="number"
          name="confirm__pin"
          placeholder="COnfirm Pin"
          className="form__control"
          value={account.confirmPin}
          onChange={(e) =>
            setAccount({ ...account, confirmPin: e.target.value })
          }
        />
      </div>
    </div>
  );
};

const AccountForm = () => {
  const [position, setPosition] = useState(0);
  const [account, setAccount] = useState({
    type: "",
    phoneNumber: "",
    pin: "",
    confirmPin: "",
  });

  const steps = [
    {
      name: "First Step",
      component: <FirstStep account={account} setAccount={setAccount} />,
    },
    {
      name: "Pin",
      component: <PinStep account={account} setAccount={setAccount} />,
    },
  ];
  const handlePosition = () => {
    if (!account.type || !account.phoneNumber || !account.pin) {
      toast.error("Please provide all fields 😞");
      return;
    }
    if (account.pin !== account.confirmPin) {
      toast.error("Confirm pin must be equal to pin");
      return;
    }
    if (position === 0) {
      setPosition(position + 1);
      return;
    }
  };
  return (
    <>
      <div className="account__formSection">
        <div className="shadow p-3 mb-5 bg-body rounded">
          <h3>Account Form</h3>
          {steps[position].component}
          {position === 0 && <button onClick={handlePosition}>Continue</button>}
          {position === 1 && <button>Create Account</button>}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AccountForm;
