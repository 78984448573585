import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'

const MerchantProfile = () => {
  const merchant = useSelector((state) => state.merchant.merchant);
  const {profile} = useSelector(state => state.accountType)
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (profile === "Personal Account") {
      navigate('/dashboard/personal/profile')
    }
    // eslint-disable-next-line
  }, [profile])
  return (
    <div className="profile__section">
      {merchant && (
        <div className="profile__area shadow p-5 bg-body rounded">
          {/* Profile pics */}
          <div className="profile__pics">
            <span className="profile__initials">OJ</span>
            <p type="file">Click to upload profile image</p>
          </div>
          {/* Personal Information */}
          <div className="personal__information section">
            <div className="personal__head">
              <h3>Personal Information</h3>
              <div className="actions">
                <button>Edit Details</button>
              </div>
            </div>
            <div className="personal__details details">
              <div className="detail">
                <p>Full legal first and middle names</p>
                <h5>{merchant.firstname}</h5>
              </div>
              <div className="detail">
                <p>Last Name</p>
                <h5>{merchant.lastname}</h5>
              </div>
              <div className="detail">
                <p>Date of birth</p>
                <h5>{merchant.dateOfBirth}</h5>
              </div>
              <div className="detail">
                <p>Phone</p>
                <h5>{merchant.phone}</h5>
              </div>
            </div>
          </div>
          <div className="personal__address section">
            <h3>Business Address</h3>
            <div className="address__details details">
              <div className="detail">
                <p>Country</p>
                <h5>{merchant.location.country}</h5>
              </div>
              <div className="detail">
                <p>City</p>
                <h5>{merchant.location.city}</h5>
              </div>
              <div className="detail">
                <p>Street</p>
                <h5>{merchant.location.street}</h5>
              </div>
              <div className="detail">
                <p>State</p>
                <h5>{merchant.location.state}</h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantProfile;
