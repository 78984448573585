import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../app/userReducer/actions";

import Logo from "../../../images/Logo15.png";

const MerchantSidebar = ({ sidebarOpen, toggleSidebar, closeSideBar }) => {
  const [activeDiv, setActive] = useState("Dashboard");
  const dispatch = useDispatch();

  const handleActiveDiv = (name) => {
    setActive(name);
    closeSideBar();
  };
  const classDiv = (name) => {
    if (name === activeDiv) {
      return "active";
    } else {
      return "";
    }
  };
  const logout = () => {
    dispatch(logoutUser());
  };
  return (
    <div
      className={sidebarOpen ? "sidebar__responsive" : ""}
      id="sidebar__section"
    >
      <div className="sidebar__icon">
        <i className="fas fa-times" onClick={toggleSidebar}></i>
      </div>
      <div className="sidebar__logo">
        <Link to="/">
          <img src={Logo} alt="Rohipay Logo" onClick={toggleSidebar} />
        </Link>
      </div>

      <div className="sidebar__menu">
        <div className="sidebar__links">
          <ul>
            <Link to="/dashboard">
              <li
                className={classDiv("Dashboard")}
                onClick={() => handleActiveDiv("Dashboard")}
              >
                <i className="fas fa-th-list"></i> Dashboard
              </li>
            </Link>
            <Link to="/dashboard/receive-payments">
              <li
                className={classDiv("Accounts")}
                onClick={() => handleActiveDiv("Accounts")}
              >
                <i className="fas fa-layer-group"></i> Receive Payments
              </li>
            </Link>

            {/* <Link to="#">
              <li
                onClick={() => handleActiveDiv("Transfer")}
                className={classDiv("Transfer")}
              >
                <i className="fas fa-angle-double-up"></i> Transfer
              </li>
            </Link> */}
            <Link to="/dashboard/merchant/transactions">
              <li
                onClick={() => handleActiveDiv("Transactions")}
                className={classDiv("Transactions")}
              >
                <i className="fas fa-bars"></i> Transactions
              </li>
            </Link>
            <Link to="/dashboard">
              <li
                onClick={() => handleActiveDiv("Profile Settings")}
                className={classDiv("Profile Settings")}
              >
                <i className="fas fa-user-cog"></i> Account Settings
              </li>
            </Link>
            <Link to="/dashboard/merchant/teams">
              <li
                onClick={() => handleActiveDiv("Team")}
                className={classDiv("Team")}
              >
                <i className="fas fa-users"></i> Team
              </li>
            </Link>

            <li onClick={logout} style={{cursor: "pointer"}}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </li>
          </ul>
        </div>

        {/* <div className="sidebar__copyright">
          <h3>Rohipay.</h3>
          <p>
            All rights reserved. Copyright &copy; 2021,{" "}
            
            <a target="_blank" href="https://www.bethedigitech.com">
              betheldigitech.com
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default MerchantSidebar;
