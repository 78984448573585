import React from "react";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

const Account = () => {
  const { profile, accounts } = useSelector((state) => state.user);
  const user = JSON.parse(sessionStorage.getItem("user"));
  console.log(user);
  return (
    <div className="personal__accounts">
      <h3 className="mb-3">Account Services</h3>
      {accounts.length > 0 ? (
        <Accordion defaultActiveKey={0}>
          <div className="account__card mb-2">
            <Accordion.Item eventKey={0}>
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Account Details</h5>
                    <p>View account details</p>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="settings__body">
                  <div className="row">
                    {accounts.length > 0 ? (
                      <>
                        <div className="col-md-6">
                          <h5>Account Number</h5>
                          <p>{accounts[0].accountNumber}</p>
                        </div>
                        <div className="col-md-6">
                          <h5>Account Name</h5>
                          <p>{accounts[0].accountName}</p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {user.profiletype === "Personal" && (
                    <>
                      <h5 className="mb-4">Accounts</h5>
                      {accounts.length > 0 && Object.keys(profile).length> 0
                        ? profile.personalcurrencies.map((currency, index) => (
                            <div className="currency" key={index}>
                              {currency.currency === "USD" && (
                                <img
                                  src="https://media.istockphoto.com/photos/flag-of-the-united-states-picture-id524055319?b=1&k=20&m=524055319&s=170667a&w=0&h=BA4elkjlqvCXyXaB5Tg7jcqpqcj9vukKGQ-DaYgrpm0="
                                  alt="USD IAvatar"
                                />
                              )}
                              {currency.currency === "EUR" && (
                                <img
                                  src="https://media.istockphoto.com/photos/european-union-flag-picture-id530234499?b=1&k=20&m=530234499&s=170667a&w=0&h=DXUIDCjlPOPr6W591du0ceHOBTxI-a_XE2zMtn2VatA="
                                  alt="USD IAvatar"
                                />
                              )}
                              {currency.currency === "GBP" && (
                                <img
                                  src="https://media.istockphoto.com/photos/british-union-jack-flag-blowing-in-the-wind-picture-id489954902?b=1&k=20&m=489954902&s=170667a&w=0&h=OYQSPpi_ZJUhCMLESHs2nAtEX5N5Wgoj77YsTGf2zB4="
                                  alt="USD IAvatar"
                                />
                              )}
                              {currency.currency === "NGN" && (
                                <img
                                  src="https://media.istockphoto.com/photos/nigerian-flag-picture-id182790719?b=1&k=20&m=182790719&s=170667a&w=0&h=DSA3gtfY-IfdDA0I5lQXH06JUo1L6YH8_2oh8Oek0qQ="
                                  alt="NGN IAvatar"
                                />
                              )}
                              <p>{currency.currency} Account</p>
                            </div>
                          ))
                        : ""}
                    </>
                  )}
                  {user.profiletype === "Merchant" && (
                    <>
                      <h5 className="mb-4">Account</h5>
                      <div className="currency">
                        {accounts[0].currency === "EUR" && (
                          <img
                            src="https://media.istockphoto.com/photos/european-union-flag-picture-id530234499?b=1&k=20&m=530234499&s=170667a&w=0&h=DXUIDCjlPOPr6W591du0ceHOBTxI-a_XE2zMtn2VatA="
                            alt="USD IAvatar"
                          />
                        )}
                        {accounts[0].currency === "USD" && (
                          <img
                            src="https://media.istockphoto.com/photos/flag-of-the-united-states-picture-id524055319?b=1&k=20&m=524055319&s=170667a&w=0&h=BA4elkjlqvCXyXaB5Tg7jcqpqcj9vukKGQ-DaYgrpm0="
                            alt="USD IAvatar"
                          />
                        )}
                        {accounts[0].currency === "GBP" && (
                          <img
                            src="https://media.istockphoto.com/photos/british-union-jack-flag-blowing-in-the-wind-picture-id489954902?b=1&k=20&m=489954902&s=170667a&w=0&h=OYQSPpi_ZJUhCMLESHs2nAtEX5N5Wgoj77YsTGf2zB4="
                            alt="USD IAvatar"
                          />
                        )}
                        {accounts[0].currency === "NGN" && (
                          <img
                            src="https://media.istockphoto.com/photos/nigerian-flag-picture-id182790719?b=1&k=20&m=182790719&s=170667a&w=0&h=DSA3gtfY-IfdDA0I5lQXH06JUo1L6YH8_2oh8Oek0qQ="
                            alt="USD IAvatar"
                          />
                        )}
                        <p>{accounts[0].currency} Account</p>
                      </div>
                    </>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className="account__card mb-2">
            <Accordion.Item eventKey={1}>
              <Accordion.Header>
                <div className="settings__card">
                  <div className="settings__icon">
                    <i className="fas fa-credit-card"></i>
                  </div>
                  <div className="settings__action">
                    <h5>Account Statements</h5>
                    <p>Download account statements for your account</p>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="settings__body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form__group">
                        <p>Start Date</p>
                        <input type="date" name="start date" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form__group">
                        <p>End Date</p>
                        <input type="date" name="end date" />
                      </div>
                    </div>
                  </div>
                  <div className="form__group">
                    <button>Download</button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </Accordion>
      ) : (
        <ReactLoading color="#000e5e" type="spin" width={30} height={30} />
      )}
    </div>
  );
};

export default Account;
