import React from "react";
import MobileApp from "../components/MobileApp";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Landing = () => {
  const state = useSelector((state) => state.user);
  console.log(state);
  return (
    <div>
      <Navbar />
      <div className="landing__section">
        {/* Hero */}
        <div className="landing__hero">
          <h1>
            First <span>3 in one</span> Payment Platform.
          </h1>
        </div>
        <div className="MobileView__landing__hero">
          <h1>
            First <span>3 in one</span>
          </h1>
          <br />
          <h1>Payment</h1>
          <h1>Platform</h1>
        </div>
        {/* Details */}
        <div className="landing__details">
          <div className="description">
            <p>
              Send money, receive payment fast and spend money without worries.
              <br className="line__breaker" />
              You can sign up for Personal Account, Merchant Account & Business
              Account.
            </p>

            {state.isLoggedIn && (
              <Link to="/dashboard">
                Dashboard <i className="fas fa-arrow-right"></i>
              </Link>
            )}
            {!state.isLoggedIn && (
              <Link to="/options">
                Get Started <i className="fas fa-arrow-right"></i>
              </Link>
            )}
          </div>
          <div className="image">
            <img
              src="https://media.istockphoto.com/photos/young-man-shopping-online-picture-id1305615921?b=1&k=20&m=1305615921&s=170667a&w=0&h=nQ1qcUP8MBo5OkghDXZb_nDKgJmxjK7VkAJ4yb0n4-M="
              alt="Payment avatar"
            />
          </div>
        </div>

        {/* Section */}
        <div className="focus__here">
          <div className="description">
            <h3>Focus on Life, We handle the Rest.</h3>
            <p>
              Handle multiple accounts, schedule, and receive payments easy and
              seamlessly, on a secured platform. <strong>Rohipay.</strong>
            </p>
            <div className="focus__cards">
              <div className="card__section">
                <div className="icon">
                  <i className="fas fa-calculator"></i>
                </div>
                <div className="card__desc">
                  <h5>Frequent Reports</h5>
                  <p>
                    Weekly, monthly, and yearly statements to help you
                    effectively manage your spendings
                  </p>
                </div>
              </div>
              <div className="card__section">
                <div className="icon">
                  <i className="fas fa-money-bill-wave-alt"></i>
                </div>
                <div className="card__desc">
                  <h5>Save More, Spend Less</h5>
                  <p>
                    We care about you. Save more on Rohipay and spend less.
                    Spend only what needs to be spent.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="focus__image">
            <img
              style={{
                width: "280px",
                marginLeft: "0",
                marginRight: "0",
              }}
              src="https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHBheW1lbnQlMjBib3h8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60"
              alt="Focus avatar"
            />
          </div>
        </div>
      </div>
      {/* Accounts Area */}
      <div className="">
        <MobileApp />
      </div>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Landing;
