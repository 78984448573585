import React from "react";
import Footer from "../components/Footer";
import MobileApp from "../components/MobileApp";
import Home from "../components/Reusables/Home";

const Business = () => {
  return (
    <div>
      <Home
        title="BUSINESS ACCOUNT"
        heading="A secure way to send & receive payments for your business"
        description="Ideal for all types of businesses, including small business, SMEs, and Big Businesses."
        image="https://media.istockphoto.com/photos/social-networking-devices-and-small-business-during-covid19-pandemic-picture-id1288079880?b=1&k=20&m=1288079880&s=170667a&w=0&h=HVJi6UpBPQFaL0J9rSqEGpSiNct_K9SgqQzU7liepHc="
      />
      <MobileApp />
      <Footer />
    </div>
  );
};

export default Business;
