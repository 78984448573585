import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { url } from "../../../lib/links";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";

const token = localStorage.getItem("token");

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const MerchantQRPay = () => {
  const {
    accountType: { profile },
    personal: { personal },
    merchant: { merchant },
  } = useSelector((state) => state);
  // console.log(profile, accounts, merchantAccount);
  // console.log(personal, merchant);

  const { id } = useParams();
  const [payerAccount, setPayerAccount] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState([]);
  const [showPin, setShowPin] = useState(false);
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState({});
  const [pin, setPin] = useState("");

  const handlePayment = () => {
    if (!amount || !description || amount === 0) {
      toast.error("Incomplete credentials");
      return;
    }
    if (amount > payerAccount[0].availableBalance) {
      toast.error("Insufficient balance");
      return;
    }
    if (payerAccount[0].availableBalance - amount < 0) {
      toast.error("Insufficient Balance");
      return;
    }
    setLoading(true);
    axios
      .post(`${url}/accounts/createSingleTransferToBankDTO`, {
        auth: {
          secure: ["otp"],
          type: "transfer",
        },
        requestRef: "",
        requestType: "",
        statusWebHook: "",
        transaction: {
          amount: amount,
          channel: "web",
          customer: {
            accountNumber: [payerAccount[0].accountNumber],
            email: payerAccount[0].email,
            firstname: payerAccount[0].accountName,
            mobileNumber: "",
            sourceAccount: payerAccount[0].accountNumber,
            sourceAccountName: payerAccount[0].accountName,
            sourceCurrency: payerAccount[0].currency,
            surname: "",
          },
          destinationAccount: merchantInfo[0].accountNumber,
          destinationAccountName: merchantInfo[0].accountName,
          destinationBankCode: "",
          destinationBankName: "Rohipay",
          destinationCurrency: merchantInfo[0].currency,
          destinationNarration: description,
          latitude: 0,
          longitude: 0,
          transactionDescription: description,
          transactionRef: "",
          transactionRefParent: "",
        },
        type: "transfer",
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setPaymentResponse(response.data);
        setShowPin(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response);
      });
  };

  // Confirm Account Pin
  const handleConfirmPin = async () => {
    if (!pin) {
      toast.error("Please input your account pin");
      return;
    }
    setLoading(true);
    await axios
      .post(`${url}/accounts/verifyAccountPin`, {
        accountNumber: payerAccount[0].accountNumber,
        pin: pin,
      })
      .then((response) => {
        // setLoading(false);
        if (response.data) {
          console.log(paymentResponse);
          axios
            .post(`${url}/accounts/TransactionCompletionOBADTO`, {
              authtype: [
                {
                  autId: 0,
                  type: "OTP",
                  value: paymentResponse.data.authDetails.label,
                },
              ],
              requestRef: paymentResponse.data.transactionRef,
              status: "Done",
              transactionRef: paymentResponse.data.transactionRef,
            })
            .then((response) => {
              setLoading(false);
              console.log(response.data);
              toast.success(response.data.message);
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
        console.log(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
        toast.error(err.response.data);
      });
  };

  // Get Account of sender
  useEffect(() => {
    if (profile === "Personal Account" && personal) {
      axios
        .post(`${url}/accounts/getAllAccountDetailssDTOByCustomerID`, {
          id: personal.customerID,
        })
        .then((response) => {
          console.log(response.data);
          setPayerAccount(response.data);
        })
        .catch((err) => console.log(err.response));
    } else if (profile === "Merchant Account" && merchant) {
      axios
        .post(`${url}/accounts/getAllAccountDetailssDTOByMerchantID`, {
          id: merchant.merchandId,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => console.log(err.response));
    }
    //  eslint-disable-next-line
  }, [profile, personal, merchant]);

  // Get Merchant Account Details By ID
  useEffect(() => {
    axios
      .post(`${url}/accounts/getAllAccountDetailssDTOByMerchantID`, { id: id })
      .then((response) => {
        // console.log(response.data);
        setMerchantInfo(response.data);
      });
    // eslint-disable-next-line
  }, []);

  // Filter accounts
  useEffect(() => {
    if (payerAccount.length > 0 && profile === "Personal Account") {
      const filterAccount = payerAccount.filter(
        (account) => account.customerId === personal.customerID
      );
      if (filterAccount) {
        setPayerAccount(filterAccount);
      }
    }
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    if (merchantInfo.length > 0) {
      const filterAccount = merchantInfo.filter(
        (account) => account.customerId === id
      );
      console.log(filterAccount);
      setMerchantInfo(filterAccount);
    }
    // eslint-disable-next-line
  }, []);

  console.log(payerAccount);
  console.log(merchantInfo);

  return (
    <>
      <div className="merchant__qrPay p-5">
        {!showPin ? (
          merchantInfo.length > 0 ? (
            <div className="info__section">
              <h3 className="mb-4">Pay Merchant</h3>
              <div className="form__group">
                <label htmlFor="Amount">Amount</label>
                <input
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="form__group">
                <label htmlFor="Merchant's Name">Merchant's Name</label>
                <input
                  type="text"
                  value={merchantInfo[0].accountName}
                  disabled
                />
              </div>
              <div className="form__group">
                <label htmlFor="Merchant's Account Number">
                  Merchant's Account Number
                </label>
                <input
                  type="text"
                  value={merchantInfo[0].accountNumber}
                  disabled
                />
              </div>
              <div className="form__group">
                <label htmlFor="Merchant's Currency">Merchant's Currency</label>
                <input type="text" value={merchantInfo[0].currency} disabled />
              </div>
              <div className="form__group">
                <label htmlFor="Transaction Description">
                  Transaction Description
                </label>
                <input
                  type="text"
                  placeholder="Transaction Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form__group">
                <button onClick={handlePayment}>
                  {!loading ? (
                    "Confirm"
                  ) : (
                    <ReactLoading
                      color="#fff"
                      type="spin"
                      width={30}
                      height={30}
                    />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <ReactLoading color="#000e5e" type="spin" width={30} height={30} />
          )
        ) : (
          <div className="info__section">
            <h3 className="mb-4">Account Pin</h3>
            <div className="form__group">
              <input
                type="text"
                placeholder="Account Pin"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
            <div className="form__group">
              <button onClick={handleConfirmPin}>
                {!loading ? (
                  "Confirm Pin"
                ) : (
                  <ReactLoading
                    color="#fff"
                    type="spin"
                    width={30}
                    height={30}
                  />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default MerchantQRPay;
