import { ToastContainer } from "react-toastify";
const initialState = {
  loading: false,
  success: false,
  merchantSuccess: false,
  accountSuccess: false,
  teamMemberSuccess: false,
};

export const utilitiesReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case "SET_SUCCESS":
      return {
        ...state,
        success: true,
      };
    case "SET_LOADING_ON":
      <ToastContainer />;
      return { ...state, loading: true };
    case "SET_LOADING_OFF":
      return { ...state, loading: false };
    case "SET_MERCHANT_SUCCESS":
      return { ...state, merchantSuccess: true };
    case "ACCOUNT_SUCCESS":
      return { ...state, accountSuccess: true };
    case "SET_TEAM_MEMBER_SUCCESS":
      <ToastContainer />;
      return { ...state, teamMemberSuccess: true };
    default:
      return state;
  }
};
