import React from "react";

const PayWithPhone = () => {
  return (
    <div className="pay__withMobile shadow p-3 m-3">
      <h3>Send money via mobile number</h3>
      <div className="form__group">
        <input
          type="text"
          name="number"
          placeholder="Enter recipient's Mobile Number"
          className="form__control"
        />
      </div>
      <div className="form__group">
        <button>Verify Phone and Pay</button>
      </div>
    </div>
  );
};

export default PayWithPhone;
