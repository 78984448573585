import React, { useState, useEffect } from "react";
import DashboardMain from "../../components/Dashboard/DashboardMain";
import DashboardNav from "../../components/Dashboard/DashboardNav";
import Sidebar from "../../components/Dashboard/Sidebar";
import { changeState } from "../../app/accountReducer/actions";

// Redux useDispatch
import { useDispatch } from "react-redux";
// Actions

import { useSelector } from "react-redux";
import MerchantSidebar from "./MerchantDashboard/MerchantSidebar";
import MerchantMain from "./MerchantDashboard/MerchantMain";

const Index = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    merchant: { merchant },
    personal: { personal },
  } = useSelector((state) => state);
  const { profile } = useSelector((state) => state.accountType);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSideBar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (profile === "" && personal) {
      dispatch(changeState("Personal Account"))
    } else if (profile === "" && merchant) {
      dispatch(changeState("Merchant Account"))
    }
  }, [dispatch, profile, personal, merchant])

  return (
    <div className="dashboard__section">
      <DashboardNav sidebar={sidebarOpen} toggleSidebar={toggleSidebar} />
      {profile === "Personal Account" && (
        <>
          <Sidebar
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
            closeSideBar={closeSideBar}
          />
          <DashboardMain />
        </>
      )}
      {profile === "Merchant Account" && (
        <>
          <MerchantSidebar
            sidebarOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
            closeSideBar={closeSideBar}
          />
          <MerchantMain />
        </>
      )}
    </div>
  );
};

export default Index;
