// eslint-disable-next-line
import React, { useState, useRef, useEffect } from "react";

const ScanAndPay = () => {

  return (
    <div className="scan__andPay shadow m-3 p-3 ">
      <h3 className="text-center">Scan and Pay</h3>
      {/* Steps */}
      <ul>
        <li>Scan the Merchant's QR Code using a QR Scanner or on our Mobile Application</li>
        <li>Click on the URL</li>
        <li>Confirm name of Receiver</li>
        <li>Confirm Amount</li>
        <li>Send funds</li>
      </ul>
    </div>
  );
};

export default ScanAndPay;
