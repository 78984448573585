import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import {
  uploadProfilePics,
  getProfilePics,
  deleteRequiredDocuments,
} from "../../../app/userReducer/actions";

const AllProfile = () => {
  const [image, setImage] = useState(null);
  // const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [profileURL, setProfileURL] = useState(null);
  const { profile, profilePics, accounts } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.utilities);
  const [documentationRequired, setDocumentationRequired] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();
  console.log(accounts);

  useEffect(() => {
    if (accounts.length > 0) {
      const filteredDocumentation = accounts[0].documentationRequired.filter(
        (document) => document.status !== "Deleted"
      );
      setDocumentationRequired(filteredDocumentation);
    }
  }, [accounts]);

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "y8frumqb");

    // console.log(image);
    // setLoading(true);
    fetch("https://api.cloudinary.com/v1_1/dl8s7ivje/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setProfileURL(res.secure_url);
        dispatch(uploadProfilePics(res.secure_url));
        dispatch(getProfilePics());
        setImage(null);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  return (
    <>
      {" "}
      {/* Merchant Profile */}
      {user.profiletype === "Merchant" && (
        <div className="profile__section bg-body shadow m-3">
          {Object.keys(profile).length > 0 ? (
            <>
              {" "}
              <Accordion defaultActiveKey={0}>
                <h3 className="mt-4 mb-1">Profile</h3>
                <Accordion.Item eventKey={0}>
                  {/* Profile Picture */}
                  <Accordion.Header>
                    <div className="profile__card mt-3 mb-3">
                      {profile.logo ? (
                        <img src={profile.logo} alt={profile.firstname} />
                      ) : (
                        <span className="profile__picsAlt">
                          {profile.firstname[0]}
                          {profile.lastname[0]}
                        </span>
                      )}
                    </div>
                  </Accordion.Header>
                  {user.userrole[0].apriviledge === "OWNER" && (
                    <Accordion.Body>
                      <div className="profile__body">
                        <h5>Change Profile Picture</h5>
                        <input type="file" />
                        <button>Change Profile Picture</button>

                        <div className="row mt-5">
                          <div className="col-sm-12 col-md-6">
                            <p>Full Name</p>
                            <h5>
                              {profile.firstname} {profile.lastname}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  )}
                </Accordion.Item>
                {/* Residential Information */}
                <h3 className="mt-4 mb-2">Business Information</h3>
                <Accordion.Item eventKey={1}>
                  <Accordion.Header>
                    <div className="profile__card">
                      <h5>Merchant Account Information</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="profile__body">
                      <div className="row mt-4">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Business Name</p>
                          <h5>{profile.companyname}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Business Type</p>
                          <h5>{profile.businesstype}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Industry</p>
                          <h5>{profile.industry}</h5>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="business__location">
                          <h3 className="mb-3">Business Location</h3>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Country</p>
                          <h5>{profile.location.country}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>City</p>
                          <h5>{profile.location.city}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Region</p>
                          <h5>{profile.location.state}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Street</p>
                          <h5>{profile.location.street}</h5>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Teams */}
                <h3 className="mt-4 mb-1">Teams</h3>
                <Accordion.Item>
                  <Accordion.Header>
                    <div className="profile__card">
                      <h5>View team members and their roles</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* Team Members */}
                    <div className="profile__body">
                      <button>Add Team Members</button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          ) : (
            <ReactLoading color="#000e5e" width={30} height={30} type="spin" />
          )}
        </div>
      )}
      {/* Personal Profile */}
      {user.profiletype === "Personal" && (
        <div className="profile__section bg-body shadow m-3">
          {Object.keys(profile).length > 0 ? (
            <>
              {" "}
              <Accordion defaultActiveKey={0}>
                <h3 className="mt-4 mb-1">Profile</h3>
                <Accordion.Item eventKey={0}>
                  {/* Profile Picture */}
                  <Accordion.Header>
                    <div className="profile__card mt-3 mb-3">
                      {profilePics ? (
                        <img
                          src={profilePics}
                          alt={"Profile Pics"}
                          width={100}
                          height={100}
                        />
                      ) : (
                        <span className="profile__picsAlt">
                          {profile.firstName[0]}
                          {profile.lastName[0]}
                        </span>
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="profile__body">
                      <h5>Change Profile Picture</h5>
                      <input
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                      {image && (
                        <button onClick={handleFileUpload}>
                          {loading ? (
                            <ReactLoading
                              color="#fff"
                              width={30}
                              height={30}
                              type="spin"
                            />
                          ) : (
                            "Change Profile Picture"
                          )}
                        </button>
                      )}

                      <div className="row mt-5">
                        <div className="col-sm-12 col-md-6">
                          <p>Full Name</p>
                          <h5>
                            {profile.firstName} {profile.lastName}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                {/* Residential Information */}
                <h3 className="mt-4 mb-2">Residential Information</h3>
                <Accordion.Item eventKey={1}>
                  <Accordion.Header>
                    <div className="profile__card">
                      <h5>Personal Account Information</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="profile__body">
                      <div className="row mt-3">
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Country</p>
                          <h5>{profile.customerAddress[0].country}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>City</p>
                          <h5>{profile.customerAddress[0].city}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Region</p>
                          <h5>{profile.customerAddress[0].state}</h5>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-4">
                          <p>Street</p>
                          <h5>{profile.customerAddress[0].street}</h5>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* Documents Uploaded */}
                <h3 className="mt-4 mb-2">Account Documents</h3>
                <Accordion.Item>
                  <Accordion.Header>
                    <div className="profile__card">
                      <h5>Account supporting documents uploaded</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="profile__body">
                      {accounts.length > 0 ? (
                        <>
                          {documentationRequired.length > 0 ? (
                            <>
                              {documentationRequired.map((document, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="uploaded__documents"
                                  >
                                    <p>{document.description}</p>
                                    <p
                                      className={
                                        document.status === "Active"
                                          ? "active__status"
                                          : "pending__status"
                                      }
                                    >
                                      Status: {document.status}
                                    </p>
                                    <button
                                      onClick={() => {
                                        dispatch(
                                          deleteRequiredDocuments(document.drId)
                                        );
                                      }}
                                    >
                                      {loading ? (
                                        <ReactLoading
                                          color="#fff"
                                          width={30}
                                          height={30}
                                          type="spin"
                                        />
                                      ) : (
                                        "Delete Record"
                                      )}
                                    </button>
                                  </div>
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <p className="mt-4 mb-4">
                                No account supporting document uploaded. Go to{" "}
                                <Link to="/dashboard/settings">
                                  Account Settings to upload documents
                                </Link>{" "}
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          ) : (
            <ReactLoading color="#000e5e" width={30} height={30} type="spin" />
          )}
        </div>
      )}
    </>
  );
};

export default AllProfile;
