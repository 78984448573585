import React from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";

const OverviewNew = () => {
  const { accounts, profile } = useSelector((state) => state.user);
  return (
    <div className="overview__new m-3 p-4 shadow bg-body">
      <h3>Dashboard</h3>
      {accounts.length > 0 ? (
        accounts[0].accountType === "Merchant" ? (
          <div>
            {accounts[0].currency === "EUR" && (
              <div className="image">
                <img
                  src="https://media.istockphoto.com/photos/european-union-flag-picture-id530234499?b=1&k=20&m=530234499&s=170667a&w=0&h=DXUIDCjlPOPr6W591du0ceHOBTxI-a_XE2zMtn2VatA="
                  alt="Currency Flag"
                />
              </div>
            )}
            {accounts[0].currency === "USD" && (
              <div className="image">
                <img
                  src="https://media.istockphoto.com/photos/flag-of-the-united-states-picture-id524055319?b=1&k=20&m=524055319&s=170667a&w=0&h=BA4elkjlqvCXyXaB5Tg7jcqpqcj9vukKGQ-DaYgrpm0="
                  alt="currency Flag"
                />
              </div>
            )}
            {accounts[0].currency === "GBP" && (
              <div className="image">
                <img
                  src="https://media.istockphoto.com/photos/british-union-jack-flag-blowing-in-the-wind-picture-id489954902?b=1&k=20&m=489954902&s=170667a&w=0&h=OYQSPpi_ZJUhCMLESHs2nAtEX5N5Wgoj77YsTGf2zB4="
                  alt="curreny Flag"
                />
              </div>
            )}
            <div className="account__currency">
              {accounts[0].currency === "USD" && (
                <h3>${accounts[0].availableBalance.toLocaleString()}</h3>
              )}
              {accounts[0].currency === "EUR" && (
                <h3>€{accounts[0].availableBalance.toLocaleString()}</h3>
              )}
              {accounts[0].currency === "GBP" && (
                <h3>£{accounts[0].availableBalance.toLocaleString()}</h3>
              )}
              {accounts[0].currency === "NGN" && (
                <h3>₦{accounts[0].availableBalance.toLocaleString()}</h3>
              )}
              <h5>{accounts[0].currency === "NGN" && "Naira Account"}</h5>
              <h5>{accounts[0].currency === "USD" && "Dollar Account"}</h5>
              <h5>{accounts[0].currency === "GBP" && "Pounds Account"}</h5>
              <h5>{accounts[0].currency === "EUR" && "Euro Account"}</h5>
            </div>
          </div>
        ) : accounts[0].accountType === "Personal" ? (
          <div className="">
            <div className="account__balances">
              <div className="default__currency">
                {accounts[0].currency === "EUR" && (
                  <div className="image">
                    <img
                      src="https://media.istockphoto.com/photos/european-union-flag-picture-id530234499?b=1&k=20&m=530234499&s=170667a&w=0&h=DXUIDCjlPOPr6W591du0ceHOBTxI-a_XE2zMtn2VatA="
                      alt="Currency Flag"
                    />
                  </div>
                )}
                {accounts[0].currency === "USD" && (
                  <div className="image">
                    <img
                      src="https://media.istockphoto.com/photos/flag-of-the-united-states-picture-id524055319?b=1&k=20&m=524055319&s=170667a&w=0&h=BA4elkjlqvCXyXaB5Tg7jcqpqcj9vukKGQ-DaYgrpm0="
                      alt="currency Flag"
                    />
                  </div>
                )}
                {accounts[0].currency === "GBP" && (
                  <div className="image">
                    <img
                      src="https://media.istockphoto.com/photos/british-union-jack-flag-blowing-in-the-wind-picture-id489954902?b=1&k=20&m=489954902&s=170667a&w=0&h=OYQSPpi_ZJUhCMLESHs2nAtEX5N5Wgoj77YsTGf2zB4="
                      alt="curreny Flag"
                    />
                  </div>
                )}
                {accounts[0].currency === "NGN" && (
                  <div className="image">
                    <img
                      src="https://media.istockphoto.com/photos/nigerian-flag-picture-id182790719?b=1&k=20&m=182790719&s=170667a&w=0&h=DSA3gtfY-IfdDA0I5lQXH06JUo1L6YH8_2oh8Oek0qQ="
                      alt="curreny Flag"
                    />
                  </div>
                )}

                <div className="account__currency">
                  {accounts[0].currency === "USD" && (
                    <h3>${accounts[0].availableBalance.toLocaleString()}</h3>
                  )}
                  {accounts[0].currency === "EUR" && (
                    <h3>€{accounts[0].availableBalance.toLocaleString()}</h3>
                  )}
                  {accounts[0].currency === "GBP" && (
                    <h3>£{accounts[0].availableBalance.toLocaleString()}</h3>
                  )}
                  {accounts[0].currency === "NGN" && (
                    <h3>₦{accounts[0].availableBalance.toLocaleString()}</h3>
                  )}
                  <h5>{accounts[0].currency === "NGN" && "Naira Account"}</h5>
                  <h5>{accounts[0].currency === "USD" && "Dollar Account"}</h5>
                  <h5>{accounts[0].currency === "GBP" && "Pounds Account"}</h5>
                  <h5>{accounts[0].currency === "EUR" && "Euro Account"}</h5>
                </div>
              </div>
              {Object.keys(profile).length > 0
                ? profile.personalcurrencies.map((currency, index) => (
                    <div key={index + currency}>
                      <div className="account__balance">
                        <div className=".">
                          {currency.currency === "USD" &&
                          currency.currency !== accounts[0].currency ? (
                            <div className="image">
                              <img
                                src="https://media.istockphoto.com/photos/flag-of-the-united-states-picture-id524055319?b=1&k=20&m=524055319&s=170667a&w=0&h=BA4elkjlqvCXyXaB5Tg7jcqpqcj9vukKGQ-DaYgrpm0="
                                alt="Dollar Flag"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="account__currency">
                            {currency.currency === "USD" &&
                            currency.currency !== accounts[0].currency ? (
                              <h3>
                                ${accounts[0].availableBalance.toLocaleString()}
                              </h3>
                            ) : (
                              ""
                            )}
                            <h5>
                              {currency.currency === "USD" &&
                              currency.currency !== accounts[0].currency
                                ? "Dollar Account"
                                : ""}
                            </h5>
                          </div>
                        </div>
                        <div className="pound__currency">
                          {currency.currency === "GBP" &&
                          currency.currency !== accounts[0].currency ? (
                            <div className="image">
                              <img
                                src="https://media.istockphoto.com/photos/british-union-jack-flag-blowing-in-the-wind-picture-id489954902?b=1&k=20&m=489954902&s=170667a&w=0&h=OYQSPpi_ZJUhCMLESHs2nAtEX5N5Wgoj77YsTGf2zB4="
                                alt="Dollar Flag"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="account__currency">
                            {currency.currency === "GBP" &&
                            currency.currency !== accounts[0].currency ? (
                              <h3>
                                £{accounts[0].availableBalance.toLocaleString()}
                              </h3>
                            ) : (
                              ""
                            )}
                            <h5>
                              {currency.currency === "GBP" &&
                              currency.currency !== accounts[0].currency
                                ? "Pounds Account"
                                : ""}
                            </h5>
                          </div>
                        </div>
                        <div className="eur__currency">
                          {currency.currency === "EUR" &&
                          currency.currency !== accounts[0].currency ? (
                            <div className="image">
                              <img
                                src="https://media.istockphoto.com/photos/european-union-flag-picture-id530234499?b=1&k=20&m=530234499&s=170667a&w=0&h=DXUIDCjlPOPr6W591du0ceHOBTxI-a_XE2zMtn2VatA="
                                alt="Dollar Flag"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="account__currency">
                            {currency.currency === "EUR" &&
                            currency.currency !== accounts[0].currency ? (
                              <h3>
                                €{accounts[0].availableBalance.toLocaleString()}
                              </h3>
                            ) : (
                              ""
                            )}
                            <h5>
                              {currency.currency === "EUR" &&
                              currency.currency !== accounts[0].currency
                                ? "Euro Account"
                                : ""}
                            </h5>
                          </div>
                        </div>
                        <div className="naira__currency">
                          {currency.currency === "NGN" &&
                          currency.currency !== accounts[0].currency ? (
                            <div className="image">
                              <img
                                src="https://media.istockphoto.com/photos/nigerian-flag-picture-id182790719?b=1&k=20&m=182790719&s=170667a&w=0&h=DSA3gtfY-IfdDA0I5lQXH06JUo1L6YH8_2oh8Oek0qQ="
                                alt="Naira Flag"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="account__currency">
                            {currency.currency === "NGN" &&
                            currency.currency !== accounts[0].currency ? (
                              <h3>
                                ₦{accounts[0].availableBalance.toLocaleString()}
                              </h3>
                            ) : (
                              ""
                            )}
                            <h5>
                              {currency.currency === "NGN" &&
                              currency.currency !== accounts[0].currency
                                ? "Naira Account"
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <ReactLoading type="spin" color="#003cbe" width={30} height={30} />
      )}
    </div>
  );
};

export default OverviewNew;
