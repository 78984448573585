import React, { useState } from "react";
import { useSelector } from "react-redux";
import EmptyTransactions from "../../../components/Reusables/EmptyTransactions";
// import Loading from "../../../components/Reusables/Loading";
import QRCode from "react-qr-code";
// eslint-disable-next-line
import { url } from '../../../lib/links'

const MerchantReceivePayments = () => {
  const { merchant } = useSelector((state) => state.merchant);
  // const [qrcode, setQrCode] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log(merchant);

  // const generateQrCode = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await QRCode.toDataURL(merchant.merchantId);
  //     setLoading(false);
  //     setQrCode(response);
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="receive__payments">
      <div className="qrcode__section shadow p-3 bg-light">
        {/* {loading ? (
          <Loading />
        ) : (
          qrcode && (
            <div className="qrcode__image mt-3 mb-5">
              <img src={qrcode} alt="Qrcode avatar" width={200} />
            </div>
          )
        )}
        {!loading && (
          <div className="qrcode__action">
            <button onClick={generateQrCode}>Generate QR Code</button>
          </div>
        )} */}
        {loading ? (
          <QRCode value={`https://rohi-pay.vercel.app/dashboard/merchant/${merchant.merchantId}`} />
        ) : (
          <button onClick={() => setLoading(true)}>Generate QR Code</button>
        )}
      </div>
      <EmptyTransactions />
    </div>
  );
};

export default MerchantReceivePayments;
