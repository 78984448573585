import React from "react";

import Transactions from "../../images/transactions.svg";

const EmptyTransactions = () => {
  return (
    <div className="empty__transaction">
      <div className="recent__transactions">
        <img src={Transactions} alt="Tranaction svg" width={100} />
        <p>Your recent transactions will appear here</p>
      </div>
    </div>
  );
};

export default EmptyTransactions;
