import React from "react";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div className="text-center p-5 m-5">
      <ReactLoading color="#003cbe" type={"spin"} width={100} height={100} />
    </div>
  );
};

export default Loading;
