const initialState = {
  personal: {},
  accounts: {},
};

export const PersonalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_PERSONAL":
      return { ...state, personal: payload };
    case "GET_ACCOUNTS":
      return {
        ...state,
        accounts: payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        personal: "",
        accounts: "",
      };
    default:
      return state;
  }
};
