const initialState = {
  merchant: {},
  merchantAccount: {},
};

export const merchantReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_MERCHANT":
      return {
        ...state,
        merchant: payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        merchant: "",
        account: {},
      };
    case "SET_ACCOUNT":
      return {
        ...state,
        merchantAccount: payload,
      };
    default:
      return state;
  }
};
